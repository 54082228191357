import { capitalize } from 'lodash';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Accordion, Layout, ListItems, Text, types } from '~/eds';
import { Filter } from '~/evifields';
import { SearchFiltersPreview } from '~/features/filters';
import { slices } from '~/redux';

import { LibraryModelVersion } from '../types';

export const CategoryScope = () => {
  const dispatch = useDispatch();

  const filters = useSelector(slices.xRayLibrary.selectors.selectFilters);

  const categoryModels = useSelector(
    slices.xRayLibrary.selectors.selectCategoryModels,
  );

  const items = useMemo(() => {
    const getCategoryModels = (models: LibraryModelVersion[]) => {
      return models.map((model) => ({
        title: model.label,
        icon: 'view' as const,
        iconColor: 'inherit' as const,
        onClick: () => {
          dispatch(slices.xRayLibrary.actions.setModelPreview(model));
        },
      }));
    };

    const items: types.Item[] = [];

    categoryModels.forEach((models, label) => {
      const categoryFilter = filters.categoryFilters[label] ?? [];

      items.push({
        title: `${capitalize(label)} (${models.length})`,
        content: (
          <Layout preset="subsections">
            <Layout bg="background.quiet" p={6} align="flex-end">
              <SearchFiltersPreview
                defaultFilterIds={['folder', 'clause', 'Contract Type']}
                filters={categoryFilter}
                //isLoading
                optional
                title="Custom Scope"
                onUpdateFilters={(updatedFilters: Filter[]) => {
                  dispatch(
                    slices.xRayLibrary.actions.setFilters({
                      ...filters,
                      categoryFilters: {
                        ...filters.categoryFilters,
                        [label]: updatedFilters,
                      },
                    }),
                  );
                }}
              />
            </Layout>
            <Accordion
              border="none"
              items={[
                {
                  title: 'Included models',
                  content: (
                    <ListItems as="ul" items={getCategoryModels(models)} />
                  ),
                },
              ]}
            />
          </Layout>
        ),
      });
    });

    return items;
  }, [categoryModels, filters]);

  return (
    <>
      <Text variant="section">Custom Scope </Text>
      <Accordion
        isIndented
        chipPosition="right"
        border="vertical"
        items={items}
      />
    </>
  );
};
