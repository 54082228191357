import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Layout, PageLayout, StatusMessage } from '~/eds';
import {
  getQuoteStatusMessageProps,
  ModelModal,
  ModelResults,
  ModelVersionInfo,
  TabKey,
  usePollModelProgress,
  useResolveTargetEntityDetails,
} from '~/features/x-ray';
import {
  CreditBalance,
  useCreditStatusMessage,
} from '~/features/x-ray/billing';
import { useCreateAction } from '~/features/x-ray/hooks';
import { FlagType, useFlag } from '~/flags';
import { api, slices } from '~/redux';
import { RoutePathType, useRouting } from '~/routing';

const Page = () => {
  const dispatch = useDispatch();
  const { location, navigate, getSearchParams, setSearchParams } = useRouting();
  const searchParam = getSearchParams();
  const enableXRayLibrary = useFlag(FlagType.DocumentXRayPrebuiltModels);
  const enableXrayNewPricingModel = useFlag(
    FlagType.DocumentXrayNewPricingModel,
  );

  const {
    data: modelSummary = {
      total: 0,
      published: 0,
      draft: 0,
      suspended: 0,
      deleted: 0,
    },
    isLoading: isLoadingSummary,
  } = api.endpoints.getModelSummary.useQuery(undefined);

  const activeModelResultInfo = useSelector(
    slices.xRay.selectors.selectActiveModelResultInfo,
  );
  const [isVisible, setIsVisible] = useState(true);

  const [targetEntityDetails] = useResolveTargetEntityDetails(
    activeModelResultInfo?.targetEntity ?? null,
  );

  const activeTargetEntityDetails =
    activeModelResultInfo?.targetEntityDetails ?? targetEntityDetails;

  const creditStatusMessage = useCreditStatusMessage();

  const { progressChip } = usePollModelProgress({
    modelId: activeModelResultInfo?.id,
  });

  const { entityType, title } = resolvePageProps(location.pathname);

  const createAction = useCreateAction(entityType);
  const browseAction = {
    level: 'primary' as const,
    text: 'Browse Model Library',
    onClick: () => navigate(RoutePathType.XRayLibrary),
  };

  const panel = activeModelResultInfo
    ? {
        children: (
          <ModelVersionInfo
            enableTitle
            modelId={activeModelResultInfo.id}
            version={activeModelResultInfo.latestVersion}
          />
        ),
        chips: [progressChip],
        hidden: {
          isHidden: false,
          onHide: () =>
            dispatch(slices.xRay.actions.setActiveModelResultInfo(null)),
        },
        title: activeTargetEntityDetails.label,
        width: 'm' as const,
      }
    : undefined;

  const quotaStatusMessage = (tabKey: TabKey) => {
    const tabs = ['all', 'deleted'];
    const { message, status } = getQuoteStatusMessageProps(tabKey);

    return tabs.includes(tabKey) && isVisible ? (
      <StatusMessage
        message={message}
        status={status}
        onDismiss={() => setIsVisible(false)}
      />
    ) : null;
  };

  const tabs = useMemo(() => {
    const getModelResults = (tabKey: TabKey) => {
      return (
        <Layout preset="sections">
          {quotaStatusMessage(tabKey)}
          <ModelResults
            tabKey={tabKey}
            entityType={entityType}
            onShowResult={(modelResult) =>
              dispatch(
                slices.xRay.actions.setActiveModelResultInfo({
                  id: modelResult.id,
                  latestVersion: modelResult.latestVersion,
                  targetEntity: modelResult.targetEntity,
                }),
              )
            }
          />
          <ModelModal />
        </Layout>
      );
    };

    const tabs = [
      {
        label: 'All models',
        panel: getModelResults('all'),
        value: 'all',
        count: modelSummary.total,
      },
      {
        label: 'Published',
        panel: getModelResults('published'),
        value: 'published',
        count: modelSummary.published,
        countStatus: 'success' as const,
      },
      {
        label: 'Suspended',
        panel: getModelResults('suspended'),
        value: 'suspended',
        count: modelSummary.suspended,
        countStatus: 'danger' as const,
      },
      {
        label: 'Drafts',
        panel: getModelResults('drafts'),
        value: 'drafts',
        count: modelSummary.draft,
        countStatus: 'warning' as const,
      },
      {
        label: 'Deleted',
        panel: getModelResults('deleted'),
        value: 'deleted',
        count: searchParam.tab === 'deleted' ? modelSummary.deleted : undefined,
      },
    ];

    return enableXrayNewPricingModel
      ? {
          tabs: tabs.map((tab) => tab),
          selectedTab: (searchParam.tab as string) ?? 'all',
          onSelectTab: (updatedTab: string) => {
            setSearchParams({
              tab: updatedTab,
              pageIndex: '1',
            });
          },
        }
      : undefined;
  }, [isLoadingSummary, modelSummary, enableXrayNewPricingModel]);

  return (
    <PageLayout
      actions={
        enableXRayLibrary
          ? [{ ...createAction, level: 'secondary' }, browseAction]
          : [createAction]
      }
      headerCalloutContent={
        enableXrayNewPricingModel ? undefined : <CreditBalance />
      }
      panel={panel}
      statusMessage={creditStatusMessage}
      title={title}
      tabs={tabs}
    >
      {!enableXrayNewPricingModel && (
        <>
          <ModelResults
            entityType={entityType}
            onShowResult={(modelResult) =>
              dispatch(
                slices.xRay.actions.setActiveModelResultInfo({
                  id: modelResult.id,
                  latestVersion: modelResult.latestVersion,
                  targetEntity: modelResult.targetEntity,
                }),
              )
            }
          />
          <ModelModal />
        </>
      )}
    </PageLayout>
  );
};

export default Page;

const resolvePageProps = (
  pathname: string,
): {
  entityType: 'field';
  title: string;
} => {
  switch (pathname) {
    case RoutePathType.AutomationHubFields:
    default:
      return {
        entityType: 'field',
        title: 'Automation Hub',
      };
  }
};
