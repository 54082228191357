import { get } from 'lodash';
import { useDispatch } from 'react-redux';

import { api } from '~/redux';
import { initContext } from '~/redux/slices/documentsNavigation';
import { ColumnSortOrder, Query } from '~/types';
import { parseNavigationResponseV3 } from '~/utils';

type Props = {
  sortBy: ColumnSortOrder;
  query: Query;
  pathname?: string;
  name?: string;
};

export const useSearchNavigation = ({
  sortBy,
  query,
  pathname = '/search',
  name = 'search results',
}: Props) => {
  const [
    getPositionByHandler,
  ] = api.endpoints.getSearchV3PositionByHandler.useLazyQuery();
  const [
    getHandlersByPosition,
  ] = api.endpoints.getSearchV3HandlersByPosition.useLazyQuery();
  const dispatch = useDispatch();

  const navigationAPI = (position: number) => {
    return getHandlersByPosition({
      sortBy,
      query,
      position,
    }).unwrap();
  };

  const getPosition = async (handler_id: number) => {
    const response = await getPositionByHandler({
      sortBy,
      handler_id,
      query,
    }).unwrap();
    return {
      position: get(response, 'data.attributes.position', -1),
      total: get(response, 'meta.total', 0),
    };
  };

  const handleNavigate = () => {
    const initOptions = {
      api: navigationAPI,
      getPosition,
      name,
      parseResponse: parseNavigationResponseV3,
      fromLocation: {
        pathname,
        hash: '',
        search: '',
      },
    };
    // @ts-ignore -- skip typing legacy code from redux upgrade
    dispatch(initContext(initOptions as any));
  };
  return [handleNavigate];
};
