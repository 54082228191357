import uuid from 'uuid';

import { exportExcel } from '~/api';
import { relationValueToOperatorId } from '~/components/SearchV2/SearchV2.utils';
import { formatDate, parseDate, types } from '~/eds';
import {
  DataFieldOperators,
  DataFieldType,
  FileExtensionType,
  QueryEntityType,
} from '~/enums';
import {
  ClauseValue,
  DatePeriod,
  DurationUnit,
  Field,
  FieldId,
  FieldType,
  Filter,
  labelsByTextSearchType,
  OperatorId,
  operatorLabelsByFieldType,
  operators,
  TextSearch,
  TextSearchContains,
  TextSearchScope,
  TextValue,
} from '~/evifields';
import { SetQueryPayload } from '~/redux/slices/search';
import { pilot } from '~/services';
import { ColumnSortOrder, Nullable, PilotId } from '~/types';
import { DEFAULT_TABLE_SORT_COLUMN_ORDER } from '~/utils/table';

import { toEviFieldType } from '../transformers';
import { DocumentHandler } from './searchV3';

interface PilotAsyncValue<V> {
  is_all_selected: boolean;
  length: number;
  search: string;
  selected_values_map: types.ValuesMap<V>;
}

// v1
type Relation = {
  value: string;
  label?: string;
} | null;
type DataField = {
  id: number | string;
  type?: DataFieldType;
  is_pinned?: boolean;
  label?: string;
  name?: string;
  first_value?: any;
  second_value?: any;
  relation?: Relation;
  multi_select_data?: PilotAsyncValue<types.ValuesMap<string>>;
};

export type TextSearchField = {
  id: number;
  area: Relation;
  contains: TextSearchContains;
  entity: QueryEntityType.TextSearch;
  scope: TextSearchScope;
  text: string[];
};

export type SectionEntity = {
  entity: QueryEntityType;
  section: PilotSearchQuery;
};

export type FieldEntity = {
  entity: QueryEntityType;
} & DataField;

export type OperatorEntity = {
  entity: QueryEntityType;
  operator: 'and' | 'or';
};

export type BooleanTextSearchEntity = {
  entity: QueryEntityType;
  query: string;
  error?: boolean;
};

export interface FolderSearchEntity {
  entity: string;
  folder_ids: unknown[];
  folders?: [];
}

export interface TextSearchEntity extends TextSearch {
  area?: {
    value: string;
    label: string;
  };
  entity?: string;
  error?: boolean;
}

export type ClauseEntity = {
  entity: QueryEntityType;
  contains: TextSearchContains;
  provision: string;
  text_search: TextSearch[];
};

export type QueryEntity =
  | SectionEntity
  | ClauseEntity
  | FieldEntity
  | OperatorEntity
  | BooleanTextSearchEntity
  | TextSearchEntity
  | FolderSearchEntity;

export type PilotSearchQuery = QueryEntity[];

export type SaveSearchEntity = {
  id?: number;
  name: string;
  query: PilotSearchQuery;
  search_visibility: string;
  user_ids?: number[];
  department_ids?: number[];
};

type SaveSearchResponse = {
  client: number;
  date_added: string;
  id: number;
  name: string;
  query: PilotSearchQuery;
  user: number;
  user_name: string;
};

type SavedSearchesTypes = 'mySearches' | 'sharedSearches';

interface SavedSearches {
  page?: number;
  types?: SavedSearchesTypes;
  pageSize?: number;
}

type SavedSearchesResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: SaveSearchResponse[];
};

interface SearchField {
  id: PilotId;
  type: DataFieldType;
}

interface SearchFields extends Array<SearchField> {}

interface SearchDocumentsParam {
  query?: PilotSearchQuery;
  fields?: SearchFields;
  sortBy?: ColumnSortOrder;
  page?: number;
  pageSize?: number;
}

interface SearchDocumentsParamV2 {
  query?: {
    booleanQuery: string;
    filters?: Filter[];
    fields?: Record<FieldId, Field>;
  };
  fields?: Field[];
  sortBy?: ColumnSortOrder;
  page?: number;
  pageSize?: number;
}

interface QuickSearchDocumentsParam {
  query: {
    booleanQuery: string;
  };
  size: number;
}

export type Folder = {
  id: number;
  name: string;
};

export interface SnippetPart {
  text: string;
  type: 'text' | 'match';
}

export type ClauseMatch = {
  clause_name: string;
  explict_clause_type_match: boolean;
  text_snippets: SnippetPart[][];
};

export type DocumentSearchResultItem = {
  contract_type_list: string[];
  date_added: string;
  document_handlers: DocumentHandler[];
  effective_date: string | null;
  ex_date: string | null;
  full_text_matches: SnippetPart[][];
  id: number;
  document_group_id?: number;
  is_movable: boolean;
  party_list: string[];
  selected_field_values?: {};
  provision_list: {
    name: string;
    content: string[];
  }[];
  // rebate_table: [];
  start_date: string | null;
};

export type SearchResponse<T> = {
  count: number;
  isDuplicateExport: boolean;
  next: string | null;
  previous: string | null;
  results: T[];
  totalDocsIncludingDups: number;
};

export const testIsSupportedFilterType = (field: QueryEntity) => {
  return (
    field.entity === QueryEntityType.Clause ||
    field.entity === QueryEntityType.Field ||
    field.entity === QueryEntityType.Folder ||
    field.entity === QueryEntityType.TextSearch
  );
};

export const testIsSupportedFilterTypeForTags = (field: QueryEntity) => {
  return (
    field.entity === QueryEntityType.Section || testIsSupportedFilterType(field)
  );
};

export const pilotSearchDocuments = ({
  query,
  fields,
  sortBy = DEFAULT_TABLE_SORT_COLUMN_ORDER,
  page = 1,
  pageSize = 20,
}: SearchDocumentsParam): Promise<SearchResponse<DocumentSearchResultItem>> => {
  return pilot.post(
    `search/?page=${page}&page_size=${pageSize}&order_by_field=${
      sortBy.id
    }&order=${sortBy.desc ? 'desc' : 'asc'}`,
    {
      query,
      fields,
    },
  );
};

export const toFilters = (query: PilotSearchQuery) => {
  return query
    .map((field) => {
      switch (field.entity) {
        case QueryEntityType.Clause:
          const fieldForEachTextSearch = (field as ClauseEntity).text_search.map(
            (fd, index) => {
              return {
                ...field,
                text_search: [(field as ClauseEntity).text_search[index]],
              };
            },
          );

          if (!fieldForEachTextSearch.length) {
            return fromEntityToClauseFilter(field as ClauseEntity);
          }

          return fieldForEachTextSearch.map((f) =>
            fromEntityToClauseFilter(f as ClauseEntity),
          );
        case QueryEntityType.Folder:
          return fromEntityToFolderFilter(field as FolderSearchEntity);
        case QueryEntityType.TextSearch:
          return fromEntityToTextSearchFilter(field as TextSearchField);
        default:
          return fromEntityToFilter(field as FieldEntity);
      }
    })
    .flat();
};

const toRelation = (operatorId: OperatorId, type?: DataFieldType) => {
  const op = operators[operatorId as OperatorId];

  // Booleans are special
  if (type === DataFieldType.BOOLEAN) {
    switch (op.id) {
      case 'is_true':
      case 'is_false':
        return null;
    }
  }

  // Number equals are special
  if (type === DataFieldType.NUMBER) {
    switch (op.id) {
      case 'equals':
        return DataFieldOperators.Exactly;
      case 'greater_than_equals':
        return DataFieldOperators.GreaterThanOrEqualsTo;
      case 'less_than_equals':
        return DataFieldOperators.LessThanOrEqualsTo;
    }
  }

  switch (op.id) {
    // Dates
    case 'date_after':
      return 'after_date';
    case 'date_before':
      return 'before_date';
    case 'date_between':
      return 'between_dates';
    case 'date_in_the_last':
      return 'last';
    case 'date_in_the_next':
      return 'next';
    case 'date_on':
      return 'on_date';

    // TODO:
    // map the rest of OperatorId back to pilot relation values base on DataFieldType
    case 'text_contains_any':
      return DataFieldOperators.ContainsText;
    case 'text_not_contains_any':
      return DataFieldOperators.DoesNotContainText;

    // Multi Select
    case 'not_contains_any':
      return DataFieldOperators.DoesNotContain;

    // Single Select
    case 'not_equals':
      return DataFieldOperators.DoesNotEqual;

    default:
      return op.id;
  }
};

const toPilotFieldType = (fieldId: FieldId, field: Field) => {
  return fromEviFieldType(field.type);
};

export type PilotDateUnit = 'Day' | 'Week' | 'Month' | 'Year';
const toPilotDateUnit = (unit: DurationUnit): PilotDateUnit => {
  switch (unit) {
    case 'days':
      return 'Day';
    case 'weeks':
      return 'Week';
    case 'months':
      return 'Month';
    case 'years':
      return 'Year';
  }
};
// for Saved/Recent Searches
const fromPilotDateUnit = (unit: PilotDateUnit): DurationUnit => {
  switch (unit) {
    case 'Day':
      return 'days';
    case 'Week':
      return 'weeks';
    case 'Month':
      return 'months';
    case 'Year':
      return 'years';
  }
};

const toPilotFieldValues = ({
  values,
  type,
  operatorId,
}: {
  values: unknown[];
  type?: DataFieldType;
  operatorId: OperatorId;
}) => {
  switch (type) {
    case DataFieldType.BOOLEAN:
      switch (operatorId) {
        case 'is_true':
          return ['true'];
        case 'is_false':
          return ['false'];
        default:
          return [];
      }
    case DataFieldType.DATE:
      switch (operatorId) {
        case 'date_in_the_last':
        case 'date_in_the_next':
          const period = values[0] as DatePeriod;
          return [period.value, toPilotDateUnit(period.unit as DurationUnit)];
        default:
          const firstDate = values[0] as Date;
          const secondDate = values[1] as Date;
          const firstDateFormatted = firstDate
            ? formatDate(firstDate, 'iso_date')
            : null;
          const secondDateFormatted = secondDate
            ? formatDate(secondDate, 'iso_date')
            : null;

          return [firstDateFormatted, secondDateFormatted];
      }
    case DataFieldType.ARRAY_MULTIPLE:
      switch (operatorId) {
        case 'contains_any':
        case 'contains_all':
        case 'not_contains_any':
          return [values];
        default:
          return values;
      }
    default:
      return values;
  }
};

const mapRelationAndValues = (
  operatorId: OperatorId,
  values: unknown[],
  type?: DataFieldType,
  asyncValue?: types.AsyncSelectValue<string>,
) => {
  const relation = toRelation(operatorId, type);
  const [first_value, second_value] = toPilotFieldValues({
    values,
    type,
    operatorId,
  });

  const mappedRelationAndValues = {
    relation: relation
      ? {
          value: relation,
        }
      : null,
    first_value,
    second_value,
  };

  if (
    (operatorId === 'contains_any' || operatorId === 'not_contains_any') &&
    type === DataFieldType.ARRAY_MULTIPLE
  ) {
    return {
      ...mappedRelationAndValues,
      multi_select_data: asyncValue
        ? {
            is_all_selected: asyncValue.isAllSelected,
            length: asyncValue.length,
            search: asyncValue.search,
            selected_values_map: asyncValue.selectedValuesMap,
          }
        : undefined,
    };
  } else {
    return mappedRelationAndValues;
  }
};

const toFieldEntity = (filter: Filter, field: Field) => {
  const { asyncValue, fieldId, operatorId, values } = filter;

  const type = field ? toPilotFieldType(fieldId, field) : DataFieldType.STRING;

  const id = fieldId === 'document_group_id' ? fieldId : Number(fieldId);

  return {
    entity: QueryEntityType.Field,
    id,
    type,
    name: field.label,
    ...mapRelationAndValues(operatorId as OperatorId, values, type, asyncValue),
  };
};

export const toProvisionContains = (operatorId: OperatorId) => {
  switch (operatorId) {
    case 'contains_any':
      return {
        value: 'contains',
        label: operatorLabelsByFieldType.clause[operatorId],
      } as TextSearchContains;
    case 'not_contains_any':
      return {
        value: 'does_not_contain',
        label: operatorLabelsByFieldType.clause[operatorId],
      } as TextSearchContains;
  }
};
export const toProvisionEntity = (filter: Filter<ClauseValue>) => {
  const { operatorId, values } = filter;

  const { provision, text_search } = values[0]!;

  return {
    entity: QueryEntityType.Clause,
    contains: toProvisionContains(operatorId!)!,
    provision,
    text_search,
  };
};

const toTextSearchContains = (operatorId: Nullable<OperatorId>) => {
  switch (operatorId) {
    case 'text_contains_all_of_these_words':
    case 'text_contains_any_of_these_words':
    case 'text_contains_exact_phrase':
      return ({
        value: 'contains',
        label: 'contains',
      } as unknown) as TextSearchContains;
    case 'text_not_contains_all_of_these_words':
    case 'text_not_contains_any_of_these_words':
    case 'text_not_contains_exact_phrase':
      return ({
        value: 'does_not_contain',
        label: 'does not contain',
      } as unknown) as TextSearchContains;
  }
};

const toTextSearchScope = (operatorId: Nullable<OperatorId>) => {
  switch (operatorId) {
    case 'text_contains_all_of_these_words':
    case 'text_not_contains_all_of_these_words':
      return ({
        value: 'all_of_these_words',
        label: 'all of these words',
      } as unknown) as TextSearchScope;
    case 'text_contains_any_of_these_words':
    case 'text_not_contains_any_of_these_words':
      return ({
        value: 'any_of_these_words',
        label: 'any of these words',
      } as unknown) as TextSearchScope;
    default:
      return ({
        value: 'exact_phrase',
        label: 'exact phrase',
      } as unknown) as TextSearchScope;
  }
};

export const toTextSearchEntity = (filter: Filter<TextValue>) => {
  const { fieldId, operatorId, values } = filter;

  return {
    area: {
      label:
        labelsByTextSearchType[fieldId as keyof typeof labelsByTextSearchType],
      value: fieldId,
    },
    contains: toTextSearchContains(operatorId)!,
    entity: QueryEntityType.TextSearch,
    scope: toTextSearchScope(operatorId),
    text: values[0],
  };
};

export const toFolderEntity = (filter: Filter, _field: Field) => {
  const { values } = filter;
  return {
    entity: 'folder',
    folder_ids: values,
  };
};

export const toEntity = (filter: Filter, field: Field) => {
  switch (field.type) {
    case 'clause':
      return toProvisionEntity(filter as Filter<ClauseValue>);
    case QueryEntityType.Folder:
      return toFolderEntity(filter, field);
    case QueryEntityType.TextSearch:
      return toTextSearchEntity(filter as Filter<TextValue>);
    default:
      return toFieldEntity(filter, field);
  }
};

const getDateObject = (dateString: string) => {
  return dateString ? parseDate(dateString) : null;
};

const getFirstSecondValues = (first_value: any, second_value: any) => {
  if (first_value && Array.isArray(first_value)) {
    if (second_value) {
      return [...first_value, second_value];
    }
  } else if (first_value) {
    return [first_value];
  } else {
    return [];
  }
};

// from Pilot to Filter eds values
const toFilterValues = ({
  first_value,
  second_value,
  relation,
  type,
}: FieldEntity) => {
  const value = relation?.value;
  switch (type) {
    case DataFieldType.BOOLEAN:
      return [];
    case DataFieldType.DATE:
      switch (value) {
        case DataFieldOperators.Last:
        case DataFieldOperators.Next:
          return [
            { unit: fromPilotDateUnit(second_value), value: first_value },
          ];
        default:
          const firstDate = getDateObject(first_value);
          const secondDate = getDateObject(second_value);
          const values = [];
          if (firstDate) {
            values.push(firstDate);
          }
          if (secondDate) {
            values.push(secondDate);
          }
          return values;
      }
    case DataFieldType.ARRAY_MULTIPLE:
      switch (value) {
        case DataFieldOperators.ContainsAny:
        case DataFieldOperators.ContainsAll:
        case DataFieldOperators.DoesNotContain:
          return [...first_value];
        default:
          return getFirstSecondValues(first_value, second_value);
      }
    default:
      return getFirstSecondValues(first_value, second_value);
  }
};

const getBooleanValues = (bool: string) => {
  if (bool === 'false') {
    return 'is_false';
  } else if (bool === 'true') {
    return 'is_true';
  }
  return null;
};

const getOperatorIdByContainsScope = (
  contains: TextSearchContains,
  scope: TextSearchScope,
) => {
  const { value: containsValue } = contains;
  const { value: scopeValue } = scope;
  return `text_${
    containsValue === 'contains' ? 'contains' : 'not_contains'
  }_${scopeValue}`;
};

const toTextSearchFilterOperatorId = (entity: TextSearchField) => {
  const { contains, scope } = entity;
  return getOperatorIdByContainsScope(contains, scope);
};

const toFilterOperatorId = (entity: FieldEntity) => {
  const { first_value, relation, type } = entity;
  if (type === DataFieldType.BOOLEAN && !relation) {
    return getBooleanValues(first_value);
  } else {
    return relationValueToOperatorId(relation!.value);
  }
};

const mapToAsyncValue = <V>(
  multiSelectData: PilotAsyncValue<V>,
): types.AsyncSelectValue<V> | undefined => {
  return {
    length: multiSelectData.length,
    isAllSelected: multiSelectData.is_all_selected,
    search: multiSelectData.search,
    selectedValuesMap: multiSelectData.selected_values_map,
  };
};

export const mapOperatorIdAndValues = (entity: FieldEntity) => {
  const operatorId = toFilterOperatorId(entity);
  const values = toFilterValues(entity);

  const asyncValue = entity.multi_select_data
    ? mapToAsyncValue(entity.multi_select_data)
    : undefined;

  if (
    (operatorId === 'contains_any' || operatorId === 'not_contains_any') &&
    entity.type === DataFieldType.ARRAY_MULTIPLE
  ) {
    return {
      operatorId,
      values,
      asyncValue,
    };
  } else {
    return {
      operatorId,
      values,
    };
  }
};

export const mapTextSearchOperatorIdAndValues = (entity: TextSearchField) => {
  const operatorId = toTextSearchFilterOperatorId(entity);
  const values = [entity.text];

  return {
    operatorId,
    values,
  };
};

export const fromEntityToFilter = (entity: FieldEntity) => {
  const { id } = entity;
  const uuidV4 = uuid.v4();
  return {
    fieldId: id,
    id: uuidV4,
    ...mapOperatorIdAndValues(entity),
  };
};

export const fromEntityToFilterNameValue = (entity: FieldEntity) => {
  return {
    name: entity.name,
    type: toEviFieldType(entity.type as DataFieldType),
    ...mapOperatorIdAndValues(entity),
  };
};

export const fromEntityToClauseFilter = (entity: ClauseEntity) => {
  const { contains, provision, text_search } = entity;
  const uuidV4 = uuid.v4();

  return {
    fieldId: 'clause',
    id: uuidV4,
    operatorId: relationValueToOperatorId(contains.value),
    values: [{ provision, text_search }],
  };
};

export const fromEntityToFolderFilter = (entity: FolderSearchEntity) => {
  const { folder_ids } = entity;
  const uuidV4 = uuid.v4();
  return {
    fieldId: 'folder',
    id: uuidV4,
    operatorId: 'in_folder',
    values: folder_ids,
  };
};

export const fromEntityToTextSearchFilter = (entity: TextSearchField) => {
  const { area } = entity;
  const uuidV4 = uuid.v4();
  return {
    fieldId: area?.value,
    id: uuidV4,
    ...mapTextSearchOperatorIdAndValues(entity),
  };
};

const AND: OperatorEntity = {
  entity: QueryEntityType.Operator,
  operator: 'and',
};

// only supports a flat query connected by AND operator (for now)
export const buildQuery = (payload?: SetQueryPayload): PilotSearchQuery => {
  if (!payload) {
    return [];
  }

  const { booleanQuery, filters = [], fields = {} } = payload;

  const query: PilotSearchQuery = booleanQuery
    ? [{ entity: QueryEntityType.BoolTextSearch, query: booleanQuery }]
    : [];

  const checkIfFieldExists = (filter: Filter) => !!fields[filter.fieldId];
  const activeFieldsFilters = filters.filter(checkIfFieldExists);

  if (activeFieldsFilters.length) {
    if (booleanQuery) {
      query.push(AND);
    }

    activeFieldsFilters.forEach((filter) => {
      const { fieldId } = filter;
      query.push(toEntity(filter, fields[fieldId]));
      query.push(AND);
    });

    query.pop(); // remove the extra AND
  }

  return query;
};

export const toSearchField = (field: Field): SearchField => ({
  id: Number(field.id),
  type: fromEviFieldType(field.type),
});

export const searchDocuments = ({
  query,
  fields,
  sortBy = DEFAULT_TABLE_SORT_COLUMN_ORDER,
  page = 1,
  pageSize = 20,
}: SearchDocumentsParamV2): Promise<SearchResponse<DocumentSearchResultItem>> =>
  pilotSearchDocuments({
    query: buildQuery(query),
    fields: fields?.map(toSearchField),
    sortBy,
    page,
    pageSize,
  });

type ValidateBooleanQueryResponse = {
  details: string | null;
};
export const validateBooleanQuery = (
  query: string,
): Promise<ValidateBooleanQueryResponse> =>
  pilot.get(`search/validate-bool-query/?query=${encodeURIComponent(query)}`);

const fromEviFieldType = (type: FieldType) => {
  switch (type) {
    case 'boolean':
      return DataFieldType.BOOLEAN;
    case 'clause':
      return DataFieldType.CLAUSE;
    case 'date':
      return DataFieldType.DATE;
    case 'enum':
      return DataFieldType.ARRAY_SINGLE;
    case 'enum_set':
      return DataFieldType.ARRAY_MULTIPLE;
    case 'file':
      return DataFieldType.ATTACHMENT;
    case 'number':
      return DataFieldType.NUMBER;
    case 'text':
      return DataFieldType.STRING;
    // TODO: do we need a text_area FieldType in evifields?
    // case 'text_area':
    //   return DataFieldType.TEXT_AREA;
    default:
      throw new Error(`invalid field type: ${type}`);
  }
};

export const saveSearch = ({
  name,
  query,
  search_visibility,
  user_ids,
  department_ids,
}: SaveSearchEntity): Promise<SaveSearchResponse> => {
  return pilot.post('saved-search/query/', {
    name,
    query,
    search_visibility,
    user_ids,
    department_ids,
  });
};

export const getSavedSearches = ({
  page,
  pageSize,
  types,
}: SavedSearches): Promise<SavedSearchesResponse> => {
  return pilot.get('saved-search/query/', {
    params: { page, page_size: pageSize, types },
  });
};

export const deleteSavedSearch = (queryId: number) => {
  return pilot.remove(`saved-search/query/${queryId}`);
};

export const updateSavedSearch = ({
  id,
  name,
  search_visibility,
  user_ids,
  department_ids,
}: SaveSearchEntity): Promise<SaveSearchResponse> =>
  pilot.patch(`saved-search/query/${id}`, {
    name,
    search_visibility,
    user_ids,
    department_ids,
  });

interface ExportSearchResultsParam {
  exportSetting: unknown;
  offsetFromUTCInHour: number;
  query: SetQueryPayload;
  sortBy: string;
}
export const exportSearchResults = ({
  query,
  ...rest
}: ExportSearchResultsParam) => {
  return exportExcel({ ...rest, query });
};

export interface NavigationHandlerParam {
  order_by_field: string;
  order: string;
  handler_id?: number;
  position?: number;
  query: PilotSearchQuery;
  order_by?: string;
}

export interface FolderNavigationParams {
  folderId: number;
  handlerId: number;
  orderByField: string | number;
  order: string;
  position?: number;
}

export const getSearchPositionByHandler = ({
  order_by_field,
  order,
  handler_id,
  query,
  order_by,
}: NavigationHandlerParam) => {
  return pilot.post(
    'search/position-by-handler/',
    { handler_id, query, order_by },
    { params: { order_by_field, order } },
  );
};

export const getSearchHandlersByPosition = ({
  order_by_field,
  order,
  position,
  query,
  order_by,
}: NavigationHandlerParam) => {
  return pilot.post(
    'search/handlers-by-position/',
    { position, query, order_by },
    { params: { order_by_field, order } },
  );
};

export const getFolderPositionByHandler = ({
  folderId,
  handlerId,
  order,
  orderByField,
}: FolderNavigationParams) => {
  return pilot.get(`folder/position-by-handler/${folderId}/`, {
    params: { handler_id: handlerId, order, order_by_field: orderByField },
  });
};

export const getFolderHandlersByPosition = ({
  folderId,
  position,
  order,
  orderByField,
}: FolderNavigationParams) => {
  return pilot.get(`folder/handlers-by-position/${folderId}/`, {
    params: { position, order, order_by_field: orderByField },
  });
};

export const getFolders = ({
  folders = [],
  search = '',
}: {
  folders: { label: string }[];
  search?: string;
}) => {
  const re = new RegExp(search, 'gi');
  let filtered = search
    ? folders.filter((folder) => !!folder.label.match(re))
    : folders;

  filtered = filtered.slice(0, 100);

  return Promise.resolve(filtered);
};

type DownloadExportReportIDResponse = {
  url: string;
};

export const checkDownloadExportReportById = (
  id: string,
): Promise<DownloadExportReportIDResponse> => {
  return pilot.get(`search/download-export-report/${id}/`);
};

type recentlyViewedDocument = {
  document_handler_id: number;
  document_name: string;
  file_type: FileExtensionType;
  path: Folder[];
};

export type RecentSearches = {
  query: PilotSearchQuery;
  id: number;
};

type QuickSearchRecordsResponse = {
  recent_document_handlers: recentlyViewedDocument[];
  recent_searches: RecentSearches[];
};

type QuickSearchDocuments = {
  document_id: number;
  document_name: string;
  file_type: FileExtensionType;
  path: Folder[];
};

type QuickSearchDocumentsResponse = {
  documents: {
    count: number;
    results: QuickSearchDocuments[];
  };
};

export const getQuickSearchRecords = (): Promise<QuickSearchRecordsResponse> =>
  pilot.get('quick-search/');

export const quickSearchDocuments = ({
  query,
  size,
}: QuickSearchDocumentsParam): Promise<QuickSearchDocumentsResponse> =>
  pilot.post('quick-search/', { query: buildQuery(query), size });

// Legacy endpoint, replace in EKP-11222
export const updatePinnedField = ({ id }: { id: string }) => {
  const url = `alert/${id}/pin-field/`;
  return pilot.patch(url);
};
