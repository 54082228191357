import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Button } from '~/eds';
import {
  ClientSyncType,
  ClientType,
  CloudType,
  FeatureFlagType,
  SyncType,
} from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { actions, api } from '~/redux';
import {
  Alert,
  FlexLayout,
  InputLabel,
  MultiSelect,
  PageLayout,
  SingleSelect,
  Text,
  TextInput,
  useModalSimple,
} from '~/ui';
import { sortByStringValue } from '~/utils/array';

const CLIENT_OPTIONS = [
  { value: ClientType.Onboarding, label: 'Onboarding' },
  { value: ClientType.ChurnedClient, label: 'Churned Client' },
  { value: ClientType.Internal, label: 'Internal' },
  { value: ClientType.LiveClient, label: 'Live Client' },
  { value: ClientType.Poc, label: 'Proof of Concept' },
  { value: ClientType.QA, label: 'QA' },
  { value: ClientType.Normal, label: 'Normal Client', isDisabled: true },
  { value: ClientType.Pilot, label: 'Pilot', isDisabled: true },
];

const FEATURE_FLAG_OPTIONS = [
  {
    value: FeatureFlagType.AllowClauseRenaming,
    label: '(ENGINEERING USE ONLY) Automation Hub: Allow Clause Renaming',
  },
  {
    value: FeatureFlagType.EsWeightedSearch,
    label: '(ENGINEERING USE ONLY) Automation Hub: Weighted Search',
  },
  {
    value: FeatureFlagType.DocumentGroupRevamp,
    label: 'Document Group Revamp',
  },
  { value: FeatureFlagType.InboundEmail, label: 'Email Intake' },
  { value: FeatureFlagType.ExportExcel, label: 'Export Excel' },
  { value: FeatureFlagType.ExternalApi, label: 'External API' },
  { value: FeatureFlagType.SearchV2, label: 'Search V2' },
  { value: FeatureFlagType.SingleSignOn, label: 'Single Sign-On' },
  { value: FeatureFlagType.Workflow, label: 'Workflow' },
  {
    value: FeatureFlagType.BulkDocumentMetadata,
    label: 'Bulk Metadata Updater',
  },
  { value: FeatureFlagType.SyncPairWebhook, label: 'Evisync Webhook' },
];

function ClientEditPage({
  title,
  errorMessage,
  isLoading,
  clientData,
  currentPage,
  customAlgorithmChoices,
  onSubmit,
  onCancel,
}) {
  const [featurePrerequisite, setFeaturePrerequisite] = useState(null);
  const [featureDependencies, setFeatureDependencies] = useState([]);
  const isSuperAdminCreateWorkdayTenantEnabled = useFlag(
    FlagType.SuperAdminCreateWorkdayTenant,
  );

  const enableXrayNewPricingModel = useFlag(
    FlagType.DocumentXrayNewPricingModel,
  );

  const getFeatureFlagFields = () => {
    if (!clientData.featureFlagMappedFields) {
      return [];
    }
    return clientData.featureFlagMappedFields.map((f) => f.id);
  };

  const isCreate = currentPage === 'create';

  const DEFAULT_FEATURE_FLAGS = isCreate
    ? {
        dashboardEnabled: true,
        hideExpirationsEnabled: true,
        inboundEmailEnabled: true,
        externalApiEnabled: true,
        syncPairWebhookEnabled: true,
      }
    : {};

  const bulkDocumentMetadataMultipleTasksValue = {
    value: FeatureFlagType.BulkDocumentMetadataMultipleTasks,
    label: 'Bulk Metadata Updater: Fast Processing',
  };

  const [configs, setConfigs] = useState({
    ...clientData,
    ...DEFAULT_FEATURE_FLAGS,
    subdomain: clientData.subDomain,
    storageProvider: clientData.storageProvider || CloudType.Aws,
    clientType: clientData.clientType || ClientType.Internal,
    syncType: clientData.syncType || SyncType.Evifs,
    featureFlagFields: getFeatureFlagFields(),
    clauseCountLimit: clientData.clauseCountLimit || 5,
    xrayCountLimit: clientData.xrayCountLimit || 5,
  });

  const isDuplicateSubdomain = errorMessage?.includes(
    'Client with this domain already exists!',
  );

  const { data: existingClient } = api.endpoints.getClientBySubdomain.useQuery(
    { subdomain: configs.subdomain },
    { skip: !isDuplicateSubdomain },
  );

  const {
    data: automationData,
    isFetching: isFetchingAutomationData,
  } = api.endpoints.getClientClause.useQuery(
    { clientId: configs.id },
    { skip: !configs.id },
  );

  const {
    data: xrayQuota,
    isFetching: isFetchingXrayQuota,
  } = api.endpoints.getModelQuota.useQuery(
    { clientId: configs.id },
    { skip: !configs.id },
  );

  const [
    featureDependentWarningModal,
    showFeatureDependentWarningModal,
  ] = useModalSimple({
    confirm: () => disableDependencies(),
    text: 'Yes, proceed',
    title: 'Turning Off a Prerequisite',
    content: (
      <FlexLayout flexDirection="column" space={4}>
        {featurePrerequisite && featureDependencies && (
          <Text variant="xs-dense">
            The function <strong>{featurePrerequisite.label}</strong> is a
            prerequisite of the following function(s):
            <ul>
              {featureDependencies.map((dependency) => (
                <li>{dependency.label}</li>
              ))}
            </ul>
            Proceeding will remove all of them together. Are you sure?
          </Text>
        )}
      </FlexLayout>
    ),
  });

  if (!currentPage) return null;

  const isEvifs = configs.syncType === ClientSyncType.Evifs;

  const getReadableErrorMessage = (msg) => {
    if (msg?.includes('domain already exists')) {
      return `${msg} Client Name: '${existingClient?.client.name}', Client Id: ${existingClient?.client.id}`;
    }

    if (msg?.includes('clause_count_limit')) {
      return msg.replace('clause_count_limit', 'Number of Custom Clauses');
    }

    if (msg?.includes('xray_count_limit')) {
      return msg.replace('xray_count_limit', 'Number of X-Ray Models');
    }

    return msg;
  };

  const disablePdfPreviewHighlighting = () => {
    setConfigs({
      ...configs,
      pdfPreviewHighlightingEnabled: false,
      postSigActivityFeedEnabled: false,
    });
  };

  const disableBulkDocumentMetadata = () => {
    setConfigs({
      ...configs,
      bulkDocumentMetadataEnabled: false,
      bulkDocumentMetadataFileMoveEnabled: false,
      bulkDocumentMetadataFilenameUpdateEnabled: false,
      bulkDocumentMetadataMultipleTasksEnabled: false,
    });
  };

  const disableBulkDocumentMetadataMultipleTasks = () => {
    setConfigs({
      ...configs,
      bulkDocumentMetadataMultipleTasksEnabled: false,
      bulkDocumentMetadataFileMoveEnabled: false,
    });
  };

  const onCreateNewClient = () => {
    onSubmit({ ...configs, storageProvider: CloudType.Aws });
  };

  const onUpdateCurClient = () => {
    onSubmit({ ...configs, clientId: configs.id });
  };

  const onSetPdfPreviewHighlightingDependencies = () => {
    setFeaturePrerequisite({
      value: FeatureFlagType.PdfPreviewHighlighting,
      label: 'PDF Preview Highlighting',
    });

    if (configs.postSigActivityFeedEnabled) {
      setFeatureDependencies([
        {
          value: FeatureFlagType.PostSigActivityFeed,
          label: 'Post Sig Commenting (POST_SIG_ACTIVITY_FEED)',
        },
      ]);
    }
  };

  const onDisablePdfPreviewHighlighting = () => {
    if (configs.postSigActivityFeedEnabled) {
      onSetPdfPreviewHighlightingDependencies();
      showFeatureDependentWarningModal();
    } else {
      disablePdfPreviewHighlighting();
    }
  };

  const onSetBulkDocumentMetadataDependencies = () => {
    setFeaturePrerequisite({
      value: FeatureFlagType.BulkDocumentMetadata,
      label: 'Bulk Metadata Updater',
    });
    const dependencies = [];
    if (configs.bulkDocumentMetadataMultipleTasksEnabled) {
      dependencies.push(bulkDocumentMetadataMultipleTasksValue);
    }
    if (configs.bulkDocumentMetadataFileMoveEnabled) {
      dependencies.push({
        value: FeatureFlagType.BulkDocumentMetadataFileMove,
        label: 'Bulk Metadata Updater: Move Files',
      });
    }
    if (configs.bulkDocumentMetadataFilenameUpdateEnabled) {
      dependencies.push({
        value: FeatureFlagType.BulkDocumentMetadataFilenameUpdate,
        label: 'Bulk Metadata Updater: Rename Files',
      });
    }
    setFeatureDependencies(dependencies);
  };

  const onDisableBulkDocumentMetadata = () => {
    if (
      configs.bulkDocumentMetadataFilenameUpdateEnabled ||
      configs.bulkDocumentMetadataFileMoveEnabled ||
      configs.bulkDocumentMetadataMultipleTasksEnabled
    ) {
      onSetBulkDocumentMetadataDependencies();
      showFeatureDependentWarningModal();
    } else {
      disableBulkDocumentMetadata();
    }
  };

  const onSetBulkDocumentMetadataMultipleTasksDependencies = () => {
    setFeaturePrerequisite(bulkDocumentMetadataMultipleTasksValue);
    if (configs.bulkDocumentMetadataFileMoveEnabled) {
      setFeatureDependencies([
        {
          value: FeatureFlagType.BulkDocumentMetadataFileMove,
          label: 'Bulk Metadata Updater: Move Files',
        },
      ]);
    }
  };

  const onDisableBulkDocumentMetadataMultipleTasks = () => {
    if (configs.bulkDocumentMetadataFileMoveEnabled) {
      onSetBulkDocumentMetadataMultipleTasksDependencies();
      showFeatureDependentWarningModal();
    } else {
      disableBulkDocumentMetadataMultipleTasks();
    }
  };

  const getFeatureFlagOptions = () => {
    const base = [...FEATURE_FLAG_OPTIONS];

    if (configs.pdfPreviewHighlightingEnabled) {
      base.push({
        value: FeatureFlagType.PostSigActivityFeed,
        label: 'Post Sig Commenting (POST_SIG_ACTIVITY_FEED)',
      });
    }

    base.push({
      value: FeatureFlagType.AutomationSmartClauseFull,
      label: 'Automation SmartClause Full',
    });
    base.push({
      value: FeatureFlagType.AutomationSmartClauseApi,
      label: 'Automation SmartClause API',
    });

    if (configs.bulkDocumentMetadataEnabled) {
      base.push({
        value: FeatureFlagType.BulkDocumentMetadataFilenameUpdate,
        label: 'Bulk Metadata Updater: Rename Files',
      });
      base.push(bulkDocumentMetadataMultipleTasksValue);

      if (configs.bulkDocumentMetadataMultipleTasksEnabled) {
        base.push({
          value: FeatureFlagType.BulkDocumentMetadataFileMove,
          label: 'Bulk Metadata Updater: Move Files',
        });
      }
    }

    return base.sort(sortByStringValue('label'));
  };

  const getFeatureFlags = () => {
    const currentFlagValues = [];

    if (configs.pdfPreviewHighlightingEnabled) {
      currentFlagValues.push(FeatureFlagType.PdfPreviewHighlighting);

      if (configs.postSigActivityFeedEnabled) {
        currentFlagValues.push(FeatureFlagType.PostSigActivityFeed);
      }
    }
    if (configs.bulkDocumentMetadataEnabled) {
      currentFlagValues.push(FeatureFlagType.BulkDocumentMetadata);

      if (configs.bulkDocumentMetadataFilenameUpdateEnabled) {
        currentFlagValues.push(
          FeatureFlagType.BulkDocumentMetadataFilenameUpdate,
        );
      }
      if (configs.bulkDocumentMetadataMultipleTasksEnabled) {
        currentFlagValues.push(
          FeatureFlagType.BulkDocumentMetadataMultipleTasks,
        );

        if (configs.bulkDocumentMetadataFileMoveEnabled) {
          currentFlagValues.push(FeatureFlagType.BulkDocumentMetadataFileMove);
        }
      }
    }
    if (configs.documentGroupRevampEnabled) {
      currentFlagValues.push(FeatureFlagType.DocumentGroupRevamp);
    }
    if (configs.inboundEmailEnabled) {
      currentFlagValues.push(FeatureFlagType.InboundEmail);
    }
    if (configs.exportExcelEnabled) {
      currentFlagValues.push(FeatureFlagType.ExportExcel);
    }
    if (configs.externalApiEnabled) {
      currentFlagValues.push(FeatureFlagType.ExternalApi);
    }
    if (configs.automationSmartClauseFullEnabled) {
      currentFlagValues.push(FeatureFlagType.AutomationSmartClauseFull);
    }
    if (configs.automationSmartClauseApiEnabled) {
      currentFlagValues.push(FeatureFlagType.AutomationSmartClauseApi);
    }
    if (configs.workflowEnabled) {
      currentFlagValues.push(FeatureFlagType.Workflow);
    }
    if (configs.searchV2Enabled) {
      currentFlagValues.push(FeatureFlagType.SearchV2);
    }
    if (configs.ssoEnabled) {
      currentFlagValues.push(FeatureFlagType.SingleSignOn);
    }
    if (isEvifs && configs.syncPairWebhookEnabled) {
      currentFlagValues.push(FeatureFlagType.SyncPairWebhook);
    }
    if (configs.allowClauseRenamingEnabled) {
      currentFlagValues.push(FeatureFlagType.AllowClauseRenaming);
    }
    if (configs.esWeightedSearchEnabled) {
      currentFlagValues.push(FeatureFlagType.EsWeightedSearch);
    }
    return currentFlagValues?.sort() ?? [];
  };

  const setClientName = (input) => {
    const validName = input.replace(/[^a-zA-Z0-9\s]/g, '');

    setConfigs({ ...configs, name: validName });
  };

  const setBucketName = (input) => {
    setConfigs({ ...configs, bucket: input });
  };

  const setAwsAccountId = (input) => {
    setConfigs({ ...configs, awsAccountId: input });
  };

  const setTenantId = (input) => {
    const valid = input.replace(/[^a-zA-Z0-9_-]/g, '');
    setConfigs({ ...configs, tenantId: valid });
  };

  const setTenantEnvironment = (input) => {
    const valid = input.replace(/[^a-zA-Z0-9_-]/g, '');
    setConfigs({ ...configs, tenantEnv: valid });
  };

  const setDataCenter = (input) => {
    const valid = input.replace(/[^a-zA-Z0-9_-]/g, '');
    setConfigs({ ...configs, dataCenter: valid });
  };

  const setClauseCountLimit = (input) => {
    setConfigs({ ...configs, clauseCountLimit: input });
  };

  const setXrayCountLimit = (input) => {
    setConfigs({ ...configs, xrayCountLimit: input });
  };

  const setClientType = (item) => {
    setConfigs({ ...configs, clientType: item });
  };

  const setDescription = (input) => {
    setConfigs({ ...configs, descrip: input });
  };

  const setDomain = (input) => {
    const valid = input.replace(/[^a-z0-9]/g, '');

    if (valid.length > 20) {
      return false;
    }

    setConfigs({ ...configs, subdomain: valid });
  };

  const setCustomAlgorithms = (items) => {
    setConfigs({ ...configs, customAlgorithms: items });
  };

  const setFeatureFlags = (items) => {
    const enablingBulkDocumentMetadata = items.includes(
      FeatureFlagType.BulkDocumentMetadata,
    );
    const enablingBulkDocumentMetadataFileMove = items.includes(
      FeatureFlagType.BulkDocumentMetadataFileMove,
    );
    const enablingBulkDocumentMetadataFilenameUpdate = items.includes(
      FeatureFlagType.BulkDocumentMetadataFilenameUpdate,
    );
    const enablingBulkDocumentMetadataMultipleTasks = items.includes(
      FeatureFlagType.BulkDocumentMetadataMultipleTasks,
    );
    const enablingPdfPreviewHighlighting = items.includes(
      FeatureFlagType.PdfPreviewHighlighting,
    );

    if (configs.postSigActivityFeedEnabled && !enablingPdfPreviewHighlighting) {
      onDisablePdfPreviewHighlighting();
      return;
    }

    if (
      (configs.bulkDocumentMetadataMultipleTasksEnabled ||
        configs.bulkDocumentMetadataFileMoveEnabled ||
        configs.bulkDocumentMetadataFilenameUpdateEnabled) &&
      !enablingBulkDocumentMetadata
    ) {
      onDisableBulkDocumentMetadata();
      return;
    }

    if (
      configs.bulkDocumentMetadataFileMoveEnabled &&
      !enablingBulkDocumentMetadataMultipleTasks
    ) {
      onDisableBulkDocumentMetadataMultipleTasks();
      return;
    }

    const bulkDocumentMetadataMultipleTasksDisabled =
      !enablingBulkDocumentMetadataMultipleTasks &&
      !configs.bulkDocumentMetadataMultipleTasksEnabled;
    const bulkDocumentMetadataFileMoveDisabled =
      !enablingBulkDocumentMetadataFileMove &&
      !configs.bulkDocumentMetadataFileMoveEnabled;
    const bulkDocumentMetadataFilenameUpdateDisabled =
      !enablingBulkDocumentMetadataFilenameUpdate &&
      !configs.bulkDocumentMetadataFilenameUpdateEnabled;

    const shouldAutoAddBulkDocumentMetadataMultipleTasks =
      enablingBulkDocumentMetadata &&
      bulkDocumentMetadataMultipleTasksDisabled &&
      bulkDocumentMetadataFileMoveDisabled &&
      bulkDocumentMetadataFilenameUpdateDisabled;

    if (shouldAutoAddBulkDocumentMetadataMultipleTasks) {
      items.push(FeatureFlagType.BulkDocumentMetadataMultipleTasks);
    }

    setConfigs({
      ...configs,
      pdfPreviewHighlightingEnabled: items.includes(
        FeatureFlagType.PdfPreviewHighlighting,
      ),
      inboundEmailEnabled: items.includes(FeatureFlagType.InboundEmail),
      exportExcelEnabled: items.includes(FeatureFlagType.ExportExcel),
      externalApiEnabled: items.includes(FeatureFlagType.ExternalApi),
      allowClauseRenamingEnabled: items.includes(
        FeatureFlagType.AllowClauseRenaming,
      ),
      esWeightedSearchEnabled: items.includes(FeatureFlagType.EsWeightedSearch),
      automationSmartClauseFullEnabled: items.includes(
        FeatureFlagType.AutomationSmartClauseFull,
      ),
      automationSmartClauseApiEnabled: items.includes(
        FeatureFlagType.AutomationSmartClauseApi,
      ),
      workflowEnabled: items.includes(FeatureFlagType.Workflow),
      ssoEnabled: items.includes(FeatureFlagType.SingleSignOn),
      syncPairWebhookEnabled: items.includes(FeatureFlagType.SyncPairWebhook),
      searchV2Enabled: items.includes(FeatureFlagType.SearchV2),
      documentGroupRevampEnabled: items.includes(
        FeatureFlagType.DocumentGroupRevamp,
      ),
      bulkDocumentMetadataEnabled: enablingBulkDocumentMetadata,
      bulkDocumentMetadataFileMoveEnabled: enablingBulkDocumentMetadataFileMove,
      bulkDocumentMetadataFilenameUpdateEnabled: enablingBulkDocumentMetadataFilenameUpdate,
      bulkDocumentMetadataMultipleTasksEnabled: items.includes(
        FeatureFlagType.BulkDocumentMetadataMultipleTasks,
      ),
      postSigActivityFeedEnabled: items.includes(
        FeatureFlagType.PostSigActivityFeed,
      ),
    });
  };

  const disableDependencies = () => {
    const currentConfig = {};

    switch (featurePrerequisite.value) {
      case FeatureFlagType.BulkDocumentMetadata:
        currentConfig.bulkDocumentMetadataEnabled = false;
        break;
      case FeatureFlagType.BulkDocumentMetadataMultipleTasks:
        currentConfig.bulkDocumentMetadataMultipleTasksEnabled = false;
        break;
      case FeatureFlagType.PdfPreviewHighlighting:
        currentConfig.pdfPreviewHighlightingEnabled = false;
        break;
      default:
        break;
    }

    featureDependencies.forEach((dependency) => {
      switch (dependency.value) {
        case FeatureFlagType.AutomationSmartClauseFull:
          currentConfig.AutomationSmartClauseFull = false;
          break;
        case FeatureFlagType.AutomationSmartClauseApi:
          currentConfig.AutomationSmartClauseApi = false;
          break;
        case FeatureFlagType.PdfPreviewHighlighting:
          currentConfig.pdfPreviewHighlightingEnabled = false;
          break;
        case FeatureFlagType.BulkDocumentMetadataFileMove:
          currentConfig.bulkDocumentMetadataFileMoveEnabled = false;
          break;
        case FeatureFlagType.BulkDocumentMetadataFilenameUpdate:
          currentConfig.bulkDocumentMetadataFilenameUpdateEnabled = false;
          break;
        case FeatureFlagType.BulkDocumentMetadataMultipleTasks:
          currentConfig.bulkDocumentMetadataMultipleTasksEnabled = false;
          break;
        case FeatureFlagType.PostSigActivityFeed:
          currentConfig.postSigActivityFeedEnabled = false;
          break;
        default:
          break;
      }
    });

    setConfigs({ ...configs, ...currentConfig });
  };

  return (
    <PageLayout
      header={<PageLayout.Header title={{ text: title }} onClose={onCancel} />}
      footer={
        <PageLayout.Footer
          leftButton={
            <Button variant="secondary" text="Back" onClick={onCancel} />
          }
          actionButtons={[
            <Button
              isLoading={isLoading}
              text={isCreate ? 'Create new client' : 'Save Update'}
              onClick={isCreate ? onCreateNewClient : onUpdateCurClient}
              variant="primary"
            />,
          ]}
        />
      }
    >
      {errorMessage && (
        <FlexLayout
          flex="1 1 auto"
          flexDirection="column"
          mx="auto"
          my={3}
          space={4}
        >
          {errorMessage.map((message) => (
            <Alert key={message} enableIcon variant="danger">
              {getReadableErrorMessage(message)}
            </Alert>
          ))}
        </FlexLayout>
      )}
      <FlexLayout flex="1 1 auto" flexDirection="column" mx="auto" space={12}>
        <InputLabel isRequired id="client-name" label="Client Name">
          <TextInput
            autoFocus
            id="client--set-name"
            value={configs.name}
            width="fullWidth"
            onChange={setClientName}
          />
        </InputLabel>
        <FlexLayout justifyContent="space-between" space={8}>
          <InputLabel
            isRequired
            flex="1 1 auto"
            id="bucket-name"
            label="Bucket Name"
          >
            <TextInput
              id="client--read-only-bucket-name"
              value={configs.bucket}
              width="fullWidth"
              disabled={!isCreate}
              onChange={setBucketName}
            />
          </InputLabel>
          <InputLabel
            flex="1 1 auto"
            id="aws-account-id"
            label="AWS Account Id"
          >
            <TextInput
              id="client--set-aws-account-id"
              value={configs.awsAccountId}
              width="fullWidth"
              onChange={setAwsAccountId}
            />
          </InputLabel>
        </FlexLayout>
        {isSuperAdminCreateWorkdayTenantEnabled && (
          <FlexLayout justifyContent="space-between" space={8}>
            <InputLabel flex="1 1 auto" id="tenant-id" label="Workday Tenant">
              <TextInput
                id="client--set-tenant-id"
                value={configs.tenantId}
                width="fullWidth"
                disabled={!isCreate}
                onChange={setTenantId}
              />
            </InputLabel>
            <InputLabel
              flex="1 1 auto"
              id="tenant-environment"
              label="Workday Tenant Environment"
            >
              <TextInput
                id="client--set-tenant-environment"
                value={configs.tenantEnv}
                width="fullWidth"
                disabled={!isCreate}
                onChange={setTenantEnvironment}
              />
            </InputLabel>
            <InputLabel
              flex="1 1 auto"
              id="data-center"
              label="Workday Data Center"
            >
              <TextInput
                id="client--set-data-center"
                value={configs.dataCenter}
                width="fullWidth"
                disabled={!isCreate}
                onChange={setDataCenter}
              />
            </InputLabel>
          </FlexLayout>
        )}
        <InputLabel
          isRequired
          flex="1 1 auto"
          id="client-type"
          label="What type of Client?"
        >
          <SingleSelect
            id="client--set-client-type"
            width="fullWidth"
            isClearable={false}
            options={CLIENT_OPTIONS}
            value={configs.clientType}
            onChange={setClientType}
            isSearchable={true}
            shouldHideSelectedOptions={false}
          />
        </InputLabel>
        <InputLabel
          isRequired
          flex="1 1 auto"
          id="number-of-custom-clauses"
          label="Number of Custom Clauses (Minimum 5, Maximum 1000)"
        >
          <TextInput
            id="client--set-number-of-custom-clauses"
            value={configs.clauseCountLimit}
            width="fullWidth"
            onChange={setClauseCountLimit}
          />
          {!isFetchingAutomationData && automationData && (
            <Text>
              The client has created {automationData.count} custom clauses in
              this environment.
            </Text>
          )}
        </InputLabel>
        {enableXrayNewPricingModel && (
          <InputLabel
            isRequired
            flex="1 1 auto"
            id="number-of-xray-models"
            label="Number of X-Ray Models (Minimum 5, Maximum 1000)"
          >
            <TextInput
              id="client--set-number-of-xray-models"
              value={configs.xrayCountLimit}
              width="fullWidth"
              onChange={setXrayCountLimit}
            />
            {!isFetchingXrayQuota && xrayQuota && (
              <Text>
                The client has used 0 X-Ray model quota in this environment.
              </Text>
            )}
          </InputLabel>
        )}
        <InputLabel
          isRequired
          flex="1 1 auto"
          id="description"
          label="Description"
        >
          <TextInput
            id="client--set-description"
            value={configs.descrip}
            width="fullWidth"
            onChange={setDescription}
          />
        </InputLabel>
        <InputLabel isRequired flex="1 1 auto" id="domain" label="Domain">
          <TextInput
            id="client--set-domain"
            value={configs.subdomain}
            width="fullWidth"
            onChange={setDomain}
          />
        </InputLabel>
        <InputLabel
          isRequired
          flex="1 1 auto"
          id="custom-algorithms"
          label="Custom Algorithms"
        >
          <MultiSelect
            id="client--set-custom-algorithms"
            width="fullWidth"
            placeholder="Search for custom algorithms"
            options={customAlgorithmChoices}
            values={configs.customAlgorithms}
            onChange={setCustomAlgorithms}
            menuPlacement="top"
          />
        </InputLabel>
        <InputLabel
          isRequired
          flex="1 1 auto"
          id="enable-features"
          label="Enable Features"
        >
          <MultiSelect
            id="client--set-enable-features"
            invalidOptionLabelRenderer={(value) => `Removed (${value})`}
            width="fullWidth"
            isClearable={false}
            options={getFeatureFlagOptions()}
            values={getFeatureFlags()}
            onChange={setFeatureFlags}
            menuPlacement="top"
          />
        </InputLabel>
      </FlexLayout>
      {featureDependentWarningModal}
    </PageLayout>
  );
}

const mapStateToProps = ({ currentUser }) => ({ currentUser });

export default connect(mapStateToProps, {
  setCurrentUser: actions.setCurrentUser,
})(ClientEditPage);
