import { get, groupBy } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionType } from 'react-table';

import { ExportExcel } from '~/components/Shared/ExportExcel';
import { PersistedTable } from '~/components/Shared/PersistedTable';
import { SEARCH_RESULTS_LIMIT } from '~/constants/max_lengths';
import { NAV_AND_SUBNAV_HEIGHT } from '~/constants/page';
import { EXPORT_DEFAULT_TEXT } from '~/constants/search';
import { ContentContainer, Layout, Table } from '~/eds';
import { FieldId } from '~/evifields';
import { api, selectors } from '~/redux';
import { Dashboard, SearchItem, SearchResult } from '~/redux/api/methods';
import dashboardV2 from '~/redux/slices/dashboardV2';
import { RoutePathType } from '~/routing';
import { Query, SearchFilter } from '~/types';
import { getMaximumByLimit } from '~/utils/number';

import { FIRST_COLUMN_PREFIX, getFirstColumnKey } from '../utils';

type Props = {
  dashboard?: Dashboard;
  isLoading: boolean;
  data?: SearchResult;
  query: Query;
  searchFilters: Record<FieldId, SearchFilter>;
};

export const DashboardV3SearchResults = ({
  dashboard,
  isLoading,
  data,
  query,
  searchFilters,
}: Props) => {
  const page = useSelector(selectors.selectDashboardPage);
  const pageSize = useSelector(selectors.selectDashboardPageSize);
  const sortBy = useSelector(selectors.selectDashboardSortBy);
  const columnOrder = useSelector(selectors.selectDashboardColumnOrder);
  const columnWidths = useSelector(selectors.selectDashboardColumnWidths);
  const dispatch = useDispatch();

  const [showExportExcel, setShowExportExcel] = useState(false);

  const onSetColumnOrder = useCallback(
    (updatedColumnOrder: Array<string>) => {
      dispatch(dashboardV2.actions.setColumnOrder(updatedColumnOrder));
      dispatch(dashboardV2.actions.setDashboardDirty(true));
    },
    [searchFilters],
  );

  const onPaginate = useCallback(
    ({ pageIndex }: { pageIndex: number }) => {
      if (pageIndex !== page) {
        dispatch(dashboardV2.actions.setPage(pageIndex));
      }
    },
    [page],
  );

  const onPageSizeChange = useCallback((pageSize: number) => {
    dispatch(dashboardV2.actions.setPageSize(pageSize));
  }, []);

  // Using type any since there no interface for inner table state.
  const handleUpdate = (state: any, action?: ActionType) => {
    if (action?.type === 'columnDoneResizing') {
      dispatch(
        dashboardV2.actions.patchColumnWidths(
          state.columnResizing.columnWidths,
        ),
      );
    }
    if (action?.type === 'toggleSortBy') {
      dispatch(dashboardV2.actions.setSortBy(state.sortBy[0]));
    }
  };

  /*
   * Columns
   */
  const firstColumnName = getFirstColumnKey(dashboard?.entity ?? '');
  const hardcodedColumns = [
    {
      key: firstColumnName,
      title: 'Name',
      minWidth: 'm',
      cellType: 'link',
      mapCellProps: (item: SearchItem) => {
        let pathname;
        if (item.type === 'ticket') {
          pathname = RoutePathType.WorkflowTicketsTicket.replace(
            ':ticketId',
            item.id,
          );
        }
        return {
          text: get(item, `selected_field_values.${firstColumnName}.value[0]`),
          pathname,
          state: { isRedirect: true },
        };
      },
      rawWidth: columnWidths[firstColumnName],
    },
  ];

  const actions = useMemo(
    () => [
      {
        icon: 'file-spreadsheet',
        label: EXPORT_DEFAULT_TEXT,
        onClick: () => {
          setShowExportExcel(true);
        },
      },
    ],
    [],
  );

  const columns = useMemo(() => {
    return [
      ...hardcodedColumns,
      ...Object.entries(searchFilters)
        .filter(([_id, filter]) => !filter.id.startsWith(FIRST_COLUMN_PREFIX))
        .map(([id, filter]) => ({
          ...mapFilterToColumn(id, filter),
          rawWidth: columnWidths[id],
        })),
    ];
  }, [searchFilters, columnWidths, columnOrder]);

  const sectionsResult = api.endpoints.getFilterSections.useQuery(undefined, {
    skip: dashboard?.entity === 'ticket',
  });

  const { data: filterSections } = sectionsResult;

  const columnsMap = groupBy(columns, 'section');

  const columnGroups = useMemo(() => {
    if (filterSections?.fieldGroups) {
      return filterSections?.fieldGroups
        .map((group) => ({
          name: group.id,
          label: group.label,
          columns: columnsMap[group.id]?.map((c) => c.key),
        }))
        .filter((group) => group.columns?.length);
    }
    return undefined;
  }, [columnsMap, filterSections]);

  /*
   * Columns End
   */
  const placeholderContent =
    data && !data.results?.length ? { title: 'No results found' } : undefined;

  const TableComponent = dashboard?.is_default ? Table : PersistedTable;

  return (
    <ContentContainer placeholderContent={placeholderContent}>
      <Layout direction="column" spacing={8}>
        <TableComponent
          actions={actions}
          context={`DASHBOARD:${dashboard?.id}`}
          name="search result"
          totalCount={getMaximumByLimit(
            data?.meta?.total,
            SEARCH_RESULTS_LIMIT,
          )}
          columns={columns}
          columnGroups={columnGroups}
          data={data?.results}
          isLoading={isLoading}
          state={{
            columnOrder: columnOrder,
            pageIndex: page,
            pageSize,
            sortBy: [sortBy],
          }}
          onPaginate={onPaginate}
          onPageSizeChange={onPageSizeChange}
          onSetColumnOrder={onSetColumnOrder}
          onUpdate={handleUpdate}
          options={{
            enableExportXlsx: false,
            enablePageSizeSelect: true,
            enableSelectRows: false,
            enableStickyHeader: true,
            stickyHeaderOffset: NAV_AND_SUBNAV_HEIGHT,
          }}
          reactTableOptions={{
            autoResetSortBy: false,
            disableSortRemove: true,
            manualSortBy: true,
          }}
        />

        <ExportExcel
          entity={dashboard?.entity}
          query={query}
          isVisible={showExportExcel}
          onHide={() => setShowExportExcel(false)}
          totalDocs={data?.meta?.total}
          totalDupsAndDocs={data?.meta?.total}
          selectedFieldIds={columnOrder}
        />
      </Layout>
    </ContentContainer>
  );
};

function mapFilterToColumn(id: FieldId, searchFilter: SearchFilter) {
  const mapToCellProps = (filter: SearchItem) => {
    const value = filter.selected_field_values[id];
    if (searchFilter.type === 'date') {
      const dateValue = new Date(get(value, 'value[0]'));
      return {
        datetime: isNaN(dateValue.getTime()) ? null : dateValue,
        format: 'iso',
      };
    }
    return {
      text: (get(value, 'value', []) || []).join(', '),
    };
  };

  const getTableCellType = () => {
    switch (searchFilter.type) {
      case 'date':
        return 'datetime';
      case 'text':
        return 'text';
      default:
        return 'text';
    }
  };

  return {
    key: id,
    field: searchFilter,
    title: searchFilter.label,
    cellType: getTableCellType(),
    mapCellProps: mapToCellProps,
    section: searchFilter.section,
  };
}
