import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Card,
  Checkbox,
  Layout,
  Markdown,
  Section,
  Text,
  useModal,
  useToast,
  useUuid,
} from '~/eds';
import { SearchFiltersPreview } from '~/features/filters';
import { actions, api, selectors, slices } from '~/redux';
import { RoutePathType, useRouting } from '~/routing';

import { PUBLISH_ELEMENT_ID, SCOPE_THRESHOLD_WARNING } from '../constants';
import { FieldModel } from '../fields/types';
import { testIsNonOptimizedModel } from '../utils';

interface Props {
  isVisible: boolean;
  modelVersion: FieldModel;
  onCancel: () => void;
}

export const PublishQuotaModal = ({
  isVisible,
  modelVersion,
  onCancel,
}: Props) => {
  const [id] = useUuid();

  const { toast } = useToast();

  const dispatch = useDispatch();

  const { navigate } = useRouting();

  const [documentsCount, setDocumentsCount] = useState(0);
  const [enableQuotaSpend, setEnableQuotaSpend] = useState(false);
  const [publishMode, setPublishMode] = useState(false);
  const [rePublishMode, setRePublishMode] = useState(false);

  const hasScopeWarning = documentsCount > SCOPE_THRESHOLD_WARNING;

  const selectPublishMode = useSelector(selectors.selectFieldAiPublishMode);

  const draft = modelVersion && modelVersion.publishStatus === null;

  const suspended =
    modelVersion && testIsNonOptimizedModel(modelVersion, ['suspended']);

  useEffect(() => {
    if (draft && isVisible) {
      showPublishModal();
    } else if (suspended && isVisible) {
      showRepublishModal();
    }
  }, [draft, suspended, isVisible]);

  useEffect(() => {
    if (suspended) {
      dispatch(
        actions.setFieldAiPublishMode(
          rePublishMode ? 'all_docs_in_scope' : 'new_docs_in_scope',
        ),
      );
    }
  }, [draft, suspended, rePublishMode]);

  const {
    data: progress = {
      docsProcessed: 0,
      docsInScope: 0,
    },
    isLoading: isProgressLoading,
  } = api.endpoints.getPromptModelProgress.useQuery({
    modelId: modelVersion.id,
  });

  const docsUnprocessed = useMemo(() => {
    return progress.docsInScope - progress.docsProcessed;
  }, [progress.docsInScope, progress.docsProcessed]);

  const [
    publishFieldModel,
    { isLoading: isPublishingFieldModel },
  ] = api.endpoints.publishPromptModel.useMutation();

  const handlePublish = () => {
    publishFieldModel({
      modelId: modelVersion.id,
      version: modelVersion.version,
      idempotencyKey: id,
      publishMode: selectPublishMode,
    })
      .unwrap()
      .then(
        () => {
          toast({
            message: `**${
              modelVersion!.name
            }** is active and is processing documents.`,
            status: 'success',
          });
          dispatch(actions.resetFieldAi());
          dispatch(
            slices.xRay.actions.setActiveModelResultInfo({
              id: modelVersion.id,
              latestVersion: modelVersion.version,
              targetEntityDetails: {
                id: '', // not needed
                label: modelVersion.name,
                type: 'field',
              },
            }),
          );
          navigate(`${RoutePathType.AutomationHubFields}`);
        },
        () =>
          toast({
            message:
              'There was an error publishing the model. Please try again.',
            status: 'danger',
          }),
      );
  };

  const handleOnHide = () => {
    setPublishMode(false);
    onCancel();
  };

  const scope = (
    <SearchFiltersPreview
      readOnly
      optional={false}
      filters={modelVersion.config.filters}
      onUpdateFilters={() => {}}
      title="Current Scope"
      docsInScope={draft}
      onDocsInScopeFound={(docs) => setDocumentsCount(docs)}
    />
  );

  const [confirmPublishModal, showPublishModal] = useModal({
    children: (
      <Layout id={PUBLISH_ELEMENT_ID} preset="sections">
        <>
          {hasScopeWarning ? (
            <Card mode="bordered" status="warning">
              <Layout preset="sections">
                {scope}
                <Text>
                  The current scope selection will take a long time to run.
                  Consider refining your selection before continuing.
                </Text>
              </Layout>
            </Card>
          ) : (
            scope
          )}
          <Section title="Publish model">
            <Text>
              This will consume 1 model quota permanently. Are you sure you want
              to publish?
            </Text>
          </Section>
          <Checkbox
            name="confirm-quota"
            option={{
              label:
                'Yes, I understand this will use 1 model quota when I publish.',
              value: 'confirm-quota',
            }}
            onChange={() => {
              setEnableQuotaSpend(!enableQuotaSpend);
            }}
            value={enableQuotaSpend}
          />
        </>
      </Layout>
    ),
    title: 'Confirm to Publish',
    primaryAction: {
      disabled: !enableQuotaSpend,
      tooltip: !enableQuotaSpend
        ? 'Please confirm that you understand the quota spend.'
        : undefined,
      isLoading: isPublishingFieldModel,
      text: 'Publish',
      onClick: handlePublish,
    },
    onHide: handleOnHide,
  });

  const [confirmRepublishModal, showRepublishModal] = useModal({
    children: (
      <Layout id={PUBLISH_ELEMENT_ID} preset="sections">
        <>
          {scope}
          <Section title="Re-publish model">
            <Markdown
              text={`This applies edits to **${docsUnprocessed} unprocessed documents**. Editing and re-publishing models **will not consume** another model quota.`}
            />
          </Section>
          <Checkbox
            name="publish-mode"
            option={{
              label: 'I want to re-publish on all documents (not recommended)',
              value: 'all_docs_in_scope',
            }}
            onChange={(value) => {
              if (value === false) {
                setRePublishMode(false);
              }
              setPublishMode(value ?? false);
            }}
            value={publishMode}
          />
          {publishMode && (
            <Card mode="bordered" status={hasScopeWarning ? 'warning' : 'info'}>
              <Layout preset="sections">
                <Section title="Re-publish model on All Documents in scope">
                  <Markdown
                    text={`This applies edits to an additional **${progress.docsProcessed} documents** in scope, including already processed documents and may take a significant amount of time. The new answers will overwrite existing answers found by X-Ray.`}
                  />
                </Section>
                <Checkbox
                  name="publish-mode"
                  option={{
                    label: 'Yes I want to re-publish on All Documents.',
                    value: 'all_docs_in_scope',
                  }}
                  onChange={(value) => {
                    setRePublishMode(value ?? false);
                  }}
                  value={rePublishMode}
                />
              </Layout>
            </Card>
          )}
        </>
      </Layout>
    ),
    isVisible: isVisible,
    loadingContent: {
      isLoading: isProgressLoading,
      message: 'Loading progress...',
    },
    title: 'Confirm to Re-publish',
    primaryAction: {
      isLoading: isPublishingFieldModel,
      text: 'Re-publish',
      onClick: handlePublish,
    },
    onHide: handleOnHide,
  });

  return (
    <>
      {confirmPublishModal}
      {confirmRepublishModal}
    </>
  );
};
