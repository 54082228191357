import { sortStrings } from '~/utils';

import { LibraryModelVersion } from '../types';

export const getSortedCategoryModels = (
  models: LibraryModelVersion[],
): Map<string, LibraryModelVersion[]> => {
  const categoryModels = new Map<string, LibraryModelVersion[]>();
  models.forEach((model) => {
    const label = model.tags?.[0]?.label ?? 'Uncategorized';
    if (categoryModels.has(label)) {
      categoryModels.set(label, [...(categoryModels.get(label) ?? []), model]);
    } else {
      categoryModels.set(label, [model]);
    }
  });
  const labels = Array.from(categoryModels.entries());
  const sortedLabels = labels.sort((a, b) => sortStrings(a[0], b[0]));

  return new Map<string, LibraryModelVersion[]>(sortedLabels);
};
