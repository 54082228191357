import { LibraryModelVersion, Scopes } from '~/features/x-ray/library/types';
import { sylvanus } from '~/services';

import {
  LibraryPublishRequest,
  LibraryPublishResponse,
  LibraryPublishStatus,
} from '../../types/sylvanus/library';
import { buildFilterQuery } from '../searchV3';

interface RequestParams {
  models: LibraryModelVersion[];
  scopes: Scopes;
  isDraft: boolean;
}

type Request = LibraryPublishRequest;

type Response = LibraryPublishResponse;

export const publishLibraryModels = async ({
  models,
  scopes,
  isDraft,
}: RequestParams): Promise<LibraryModelVersion[]> => {
  const request: Request = {
    publishes: models.map((model: LibraryModelVersion) => {
      const scope = scopes[model.libraryModelId];
      const query = buildFilterQuery({
        booleanQuery: '',
        filters: scope,
      });

      return {
        library_model_id: model.libraryModelId,
        library_model_version_number: model.version,
        draft: isDraft,
        config_overrides: model.config.scope
          ? {
              scope: {
                data: {
                  attributes: {
                    filters: [],
                    query,
                    store_as_recent_search: false,
                  },
                },
                type: 'document',
              },
            }
          : null,
      };
    }),
  };
  const response: Response = await sylvanus.post(
    '/library/model/publish',
    request,
  );

  return response.publishes.map((modelPublish) =>
    mapPublishStatusToLibraryModelVersion(
      modelPublish,
      models.find(
        (model) => model.libraryModelId === modelPublish.library_model_id,
      ),
    ),
  );
};

const mapPublishStatusToLibraryModelVersion = (
  modelPublish: LibraryPublishStatus,
  model?: LibraryModelVersion,
): LibraryModelVersion => {
  if (!model) {
    throw new Error('Published model not found');
  }
  return {
    config: model.config,
    description: model.description,
    label: model.label,
    latest: model.latest,
    libraryModelId: modelPublish.library_model_id,
    promptModelId: modelPublish.prompt_model_id ?? null,
    publishErrorMessage: modelPublish.error_message ?? undefined,
    publishState: modelPublish.state,
    tags: model.tags,
    targetEntityDetails: model.targetEntityDetails,
    version: modelPublish.library_model_version_number,
  };
};
