import React, { useEffect, useState } from 'react';

import { Layout, LoadingShimmer, Text, useToast } from '~/eds';
import { Filter } from '~/evifields';
import { api } from '~/redux';

interface Props {
  fieldId: number | null;
  filters: Filter[];
  isVisible?: boolean;
}

export const DocumentsInScope = ({
  fieldId,
  filters,
  isVisible = false,
}: Props) => {
  const { toast } = useToast();

  const filtersWithValues = filters.filter(
    (filter) =>
      filter.values.length > 0 ||
      OPERATORS_WITH_NO_VALUES.has(filter.operatorId!),
  );

  const {
    data: docsInScope = 0,
    isLoading: isLoadingDocsInScope,
    error: errorDocsInScope,
  } = api.endpoints.getDocsInScope.useQuery(
    {
      fieldId: fieldId!,
      filters: filtersWithValues,
    },
    { skip: !fieldId || !isVisible },
  );

  const [defaultTotal, setDefaultTotal] = useState(docsInScope);

  useEffect(() => {
    setDefaultTotal(docsInScope);
  }, [filters, docsInScope, fieldId]);

  if (errorDocsInScope) {
    toast({
      status: 'danger',
      message: 'Failed to get documents in scope',
    });
  }

  return (
    <Layout preset="xs">
      <Text>Documents Found: </Text>
      {isLoadingDocsInScope ? (
        <LoadingShimmer width="25" />
      ) : (
        <Text color="status.active">{defaultTotal}</Text>
      )}
    </Layout>
  );
};

const OPERATORS_WITH_NO_VALUES = new Set([
  'is_blank',
  'is_not_blank',
  'is_true',
  'is_false',
]);
