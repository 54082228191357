import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { PersistedTable } from '~/components/Shared/PersistedTable';
import { User } from '~/components/Shared/User';
import { ContentContainer, formatDate } from '~/eds';
import { TableContextType } from '~/enums';
import { useTableSettings } from '~/hooks';
import { api, slices } from '~/redux';
import { useRouting } from '~/routing';
import { capitalize } from '~/utils/strings';

import { useCreateAction } from '../hooks';
import { ModelProgress } from '../ModelProgress';
import { ModelScope } from '../ModelScope';
import { ModelActionType, ModelResult, TabKey, TargetEntity } from '../types';
import { testIsActivePublishStatus, transformTabKeyToStatus } from '../utils';
import { ModelResultTargetEntity } from './ModelResultTargetEntity';

interface Props {
  tabKey?: TabKey;
  entityType: TargetEntity['type'];
  onShowResult: (modelResult: ModelResult) => void;
}

export const ModelResults = ({ tabKey, entityType, onShowResult }: Props) => {
  const dispatch = useDispatch();
  const { getSearchParam, setSearchParams } = useRouting();

  const isDeleteTab = tabKey === 'deleted';

  const {
    tableSettings = {
      pageSize: 10,
    },
  } = useTableSettings(TableContextType.FieldAi);

  const pageIndex = Number(getSearchParam('pageIndex') ?? 1);

  const {
    data: { results, totalCount } = {
      results: [],
      totalCount: 0,
    },
    isError,
    isFetching,
    isLoading,
  } = api.endpoints.getPromptModelResults.useQuery({
    pageIndex,
    pageSize: tableSettings.pageSize,
    status: transformTabKeyToStatus(tabKey),
  });

  const [
    updatePromptModelProgress,
    { isLoading: isUpdatingPromptModelProgress },
  ] = api.endpoints.updatePromptModelProgress.useMutation();

  const handlePaginate = useCallback(
    ({ pageIndex }: { pageIndex: number }) => {
      setSearchParams({
        pageIndex: String(pageIndex),
      });
    },
    [pageIndex],
  );

  const getRowActions = useCallback(
    (modelResult: ModelResult) => {
      const { currentPublishStatus } = modelResult;
      const modelActions = [
        currentPublishStatus === 'suspended'
          ? 'resume'
          : testIsActivePublishStatus(currentPublishStatus)
          ? 'suspend'
          : null,
        'delete',
      ].filter((action) => action !== null) as ModelActionType[];

      const disabled = isFetching || isUpdatingPromptModelProgress;

      return modelActions.map((action) => ({
        key: action,
        disabled,
        label: capitalize(action),
        tooltip: disabled ? 'Updating model…' : undefined,
        onClick: () => {
          if (action === 'resume') {
            updatePromptModelProgress({ modelId: modelResult.id });
            onShowResult(modelResult);
          }
          dispatch(
            slices.xRay.actions.setModelAction({
              model: {
                id: modelResult.id,
                entity: modelResult.targetEntity,
                name: '',
                version: modelResult.latestVersion,
              },
              action,
            }),
          );
        },
      }));
    },
    [isFetching, isUpdatingPromptModelProgress],
  );

  const hasModels = results.length > 0;

  const createAction = useCreateAction(entityType);

  const emptyContent = {
    action: createAction,
    image: 'empty-no-groups',
    title: `Build a ${capitalize(entityType)} Model`,
    message:
      'Let our AI find the information you are looking for across documents.',
  };

  const placeholderContent = isError
    ? {
        description:
          'There was an error loading X-Ray models. Refresh the page to try again.',
      }
    : !hasModels
    ? emptyContent
    : undefined;

  const isStatusDeleted = isDeleteTab
    ? []
    : [
        {
          key: 'docsInScope',
          align: 'right',
          disableSortBy: true,
          title: 'Documents in Scope',
          width: 'm',
          renderCell: (modelResult: ModelResult) => (
            <ModelProgress metric="docsInScope" modelId={modelResult.id} />
          ),
        },
        {
          key: 'valuesFound',
          align: 'right',
          disableSortBy: true,
          title: 'Values Found',
          width: 'm',
          renderCell: (modelResult: ModelResult) => (
            <ModelProgress metric="valuesFound" modelId={modelResult.id} />
          ),
        },
        {
          key: 'publishStatus',
          title: 'Status',
          disableSortBy: true,
          width: 'm',
          renderCell: (modelResult: ModelResult) => (
            <ModelProgress metric="status" modelId={modelResult.id} />
          ),
        },
      ];

  const columns = [
    {
      key: 'name',
      cellType: 'link',
      disableSortBy: true,
      title: 'Model',
      renderCell: (modelResult: ModelResult) => (
        <ModelResultTargetEntity
          modelResult={modelResult}
          readOnly={tabKey === 'deleted'}
        />
      ),
    },
    {
      key: 'scope',
      cellType: 'text',
      disableSortBy: true,
      title: 'Scope',
      width: 'l',
      renderCell: (modelResult: ModelResult) => (
        <ModelScope readOnly scope={modelResult.scope} />
      ),
    },
    {
      key: 'modifiedBy',
      cellType: 'user',
      disableSortBy: true,
      title: 'Modified By',
      mapCellProps: (modelResult: ModelResult) => ({
        asyncUser: {
          id: modelResult.modifiedBy,
          render: User,
        },
        mode: 'avatar-name',
      }),
    },
    {
      key: 'modifiedDate',
      cellType: 'datetime',
      disableSortBy: true,
      title: 'Modified Date',
      mapCellProps: (modelResult: ModelResult) => ({
        datetime: modelResult.modifiedDate,
        format: 'short',
        tooltip: modelResult.modifiedDate
          ? formatDate(modelResult.modifiedDate, 'time')
          : undefined,
      }),
    },

    ...isStatusDeleted,
  ];

  return (
    <ContentContainer
      loadingContent={{
        isLoading,
        message: 'Loading X-Ray models…',
      }}
      placeholderContent={placeholderContent}
    >
      <PersistedTable
        columns={columns}
        context={TableContextType.FieldAi}
        data={results}
        getRowActions={isDeleteTab ? undefined : getRowActions}
        name="field-model-list"
        options={{
          enableExportXlsx: false,
          enableSelectRows: false,
        }}
        reactTableOptions={{
          autoResetHiddenColumns: false,
          autoResetResize: false,
        }}
        rowDetails={
          isDeleteTab
            ? undefined
            : {
                condition: (modelResult: ModelResult) =>
                  modelResult.versions.length > 0,
                onClick: onShowResult,
              }
        }
        state={{
          pageIndex,
          pageSize: tableSettings.pageSize,
        }}
        totalCount={totalCount}
        onPaginate={handlePaginate}
      />
    </ContentContainer>
  );
};
