import { Filter } from '~/evifields';

import { LibraryModelVersion, Scopes } from '../types';

export const isEmptyScope = (scope: Filter[]) => {
  return scope.every((filter) => !filter.values || filter.values.length === 0);
};

export const updateModelFilters = (
  globalFilters: Filter[],
  modelFilters: Filter[],
) => {
  const filtersWithValues = globalFilters.filter(
    (filter) =>
      filter.values.length > 0 ||
      OPERATORS_WITH_NO_VALUES.has(filter.operatorId!),
  );

  const updatedFilters = [...modelFilters];

  filtersWithValues.forEach((globalFilter) => {
    const conflictIndex = updatedFilters.findIndex((modelFilter) => {
      return modelFilter.fieldId === globalFilter.fieldId;
    });

    if (conflictIndex !== -1) {
      updatedFilters.splice(conflictIndex, 1);
    }

    updatedFilters.push(globalFilter as Filter);
  });

  return updatedFilters;
  //rework to query for api
};

export const OPERATORS_WITH_NO_VALUES = new Set([
  'is_blank',
  'is_not_blank',
  'is_true',
  'is_false',
]);

export const updateAllModelsGlobalScope = ({
  globalFilters,
  modelsInCart,
}: {
  globalFilters: Filter[];

  modelsInCart: LibraryModelVersion[];
}) => {
  return modelsInCart.reduce((acc, model) => {
    const id = model.libraryModelId;
    acc[id] = updateModelFilters(globalFilters, model.scope ?? []);
    return acc;
  }, {} as Scopes);
};

export const updateAllModelsCustomScope = ({
  categoryModels,
  categoryFilters,
}: {
  categoryFilters?: Record<string, Filter[]>;
  categoryModels: Map<string, LibraryModelVersion[]>;
}) => {
  const result: Scopes = {};

  categoryModels.forEach((models, label) => {
    const categoryFilter = categoryFilters?.[label] || [];

    return models.forEach((model) => {
      const id = model.libraryModelId;
      result[id] = updateModelFilters(categoryFilter, model.scope ?? []);
    });
  });

  return result;
};
