import { LibraryModel } from '~/features/x-ray/library/types';
import { sylvanus } from '~/services';

import { DynamoDbPageApiLibraryModelVersion } from '../../types/sylvanus/library';
import { getFilters } from '../searchV3';
import { mapLibraryModelVersion } from './transformers';

/**
 * Fetch all the `LibaryModelVersion`s, and group them by `libraryModelId`.
 */
export const getLibraryModels = async (): Promise<LibraryModel[]> => {
  const searchFilters = await getFilters();

  const response: DynamoDbPageApiLibraryModelVersion = await sylvanus.get(
    '/library/model',
  );
  const libraryModels = new Map<string, LibraryModel>();

  response.items.forEach((item) => {
    const version = mapLibraryModelVersion(item, searchFilters);
    const libraryModelId = version.libraryModelId;

    const versions = libraryModels.get(libraryModelId)?.versions ?? [];

    libraryModels.set(libraryModelId, {
      libraryModelId,
      versions: [...versions, version],
    });
  });

  return Array.from(libraryModels.values());
};
