import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Text } from '~/eds';
import { Filter } from '~/evifields';
import { SearchFilters } from '~/features/filters';
import { slices } from '~/redux';

export const GlobalScope = () => {
  const dispatch = useDispatch();
  const filters = useSelector(slices.xRayLibrary.selectors.selectFilters);

  return (
    <>
      <Text variant="section">Global Scope</Text>
      <SearchFilters
        defaultFilterIds={['folder', 'clause', 'Contract Type']}
        filters={filters.globalFilters}
        onFilterChange={(updatedFilters: Filter[]) => {
          dispatch(
            slices.xRayLibrary.actions.setFilters({
              ...filters,
              globalFilters: updatedFilters,
            }),
          );
        }}
      />
    </>
  );
};
