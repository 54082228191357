import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_SORT_BY, STATIC_COLUMNS } from '~/constants/search';
import { Box, ContentContainer, Layout } from '~/eds';
import { TableViewContextType } from '~/enums';
import { useTableState } from '~/hooks';
import { api, selectors } from '~/redux';
import { SearchType } from '~/redux/api/methods';
import { Dashboard as DashboardType } from '~/redux/api/methods/dashboardV2';
import { buildQuery } from '~/redux/api/methods/searchV3';
import dashboardV2 from '~/redux/slices/dashboardV2';

import { BooleanSearchBar } from '../advanced-search/boolean-search-bar';
import { ResultsTable } from '../advanced-search/results-table';

export const Dashboard = ({ dashboard }: { dashboard: DashboardType }) => {
  const dispatch = useDispatch();

  const {
    data: searchFilters,
    isFetching: isFetchingFilters,
  } = api.endpoints.getFiltersV2.useQuery({
    type: dashboard.entity as SearchType,
  });
  const [_, tableViewResult] = api.endpoints.getCurrentTableView.useLazyQuery();
  const { isFetching: isFetchingTableView } = tableViewResult;
  const [updateTableView] = api.endpoints.updateTableView.useMutation();

  const {
    tableState,
    handleUpdateTableState,
    partialUpdateTableState,
  } = useTableState({
    id: `ResultsTable:${dashboard.id}`,
    columnOrder: dashboard.columns,
    sortBy: DEFAULT_SORT_BY[dashboard.entity as 'document' | 'ticket'],
  });

  const searchText = useSelector(selectors.selectDashboardSearchText);
  const activeDashboard = useSelector(selectors.selectActiveDashboard);
  const query = useMemo(() => {
    if (!searchFilters) return [];

    return buildQuery({
      booleanQuery: searchText ?? '',
      filters: [],
      searchFilters,
    });
  }, [searchText, searchFilters]);

  const {
    data: searchResponse,
    isFetching: isFetchingSearch,
  } = api.endpoints.search.useQuery({
    pageSize: tableState.pageSize,
    page: tableState.pageIndex,
    sortBy: tableState.sortBy,
    type: dashboard.entity as any,
    query,
    filters: [
      ...STATIC_COLUMNS[dashboard.entity as 'document' | 'ticket'],
      ...tableState.columnOrder,
    ],
  });

  const handleBooleanSearch = (value: string) => {
    dispatch(dashboardV2.actions.setSearchText(value));
    partialUpdateTableState({ pageIndex: 1 });
  };
  const handleColumnOrderChange = (columnOrder: string[]) => {
    partialUpdateTableState({ columnOrder });
    dispatch(dashboardV2.actions.setColumnOrder(columnOrder));
    if (dashboard.is_default) {
      updateTableView({
        context: TableViewContextType.EVISEARCH_SEARCH,
        fields: columnOrder.map((field_id, column_number) => ({
          field_id,
          column_number,
        })),
      });
    }
  };

  useEffect(() => {
    if (searchFilters) {
      dispatch(
        dashboardV2.actions.initDashboard({
          dashboard,
          searchFilters,
          tableSettings: { ...tableState, columnWidths: {} },
          defaultSortBy:
            DEFAULT_SORT_BY[dashboard.entity as 'document' | 'ticket'],
        }),
      );
    }
  }, [searchFilters, dashboard]);

  const isCurrentDashboardActive = activeDashboard.id === dashboard.id;

  const isLoading =
    isFetchingFilters || isFetchingSearch || isFetchingTableView;
  return (
    <ContentContainer
      loadingContent={{
        isLoading: !isCurrentDashboardActive,
      }}
    >
      <Layout direction="column" spacing={4}>
        <Layout direction="row" spacing={4} justify="space-between">
          <Box w="100%">
            <BooleanSearchBar
              value={searchText ?? ''}
              onSubmit={handleBooleanSearch}
              isSubmitting={isLoading}
            />
          </Box>
        </Layout>

        <ResultsTable
          data={searchResponse}
          id={dashboard.id}
          isLoading={isLoading}
          enablePersistedTable={dashboard.is_default}
          entity={dashboard.entity as SearchType}
          query={query}
          searchFilters={searchFilters ?? {}}
          state={{
            columnOrder: tableState.columnOrder,
            pageIndex: tableState.pageIndex,
            pageSize: tableState.pageSize,
            sortBy: [tableState.sortBy],
            selectedRowIds: tableState.selectedIds,
          }}
          getBulkActions={() => []}
          onUpdateState={handleUpdateTableState}
          onSetColumnOrder={handleColumnOrderChange}
          onRowDetailsClick={() => {}}
          onSaveView={() => {}}
          onLoadView={() => {}}
        />
      </Layout>
    </ContentContainer>
  );
};
