import { useDispatch, useSelector } from 'react-redux';

import { ListItems, Panel, types } from '~/eds';
import { slices } from '~/redux';
import { sortStrings } from '~/utils';

import { useGetLatestLibraryModelVersions } from '../hooks';
import { LibraryModelVersion } from '../types';

export const Categories = () => {
  const dispatch = useDispatch();
  const activeCategory = useSelector(
    slices.xRayLibrary.selectors.selectCategory,
  );
  const models = useGetLatestLibraryModelVersions({});
  const categoryLabels = getSortedCategoryLabels(models);
  const items: types.ListItem[] = [];
  categoryLabels.forEach((count, label) => {
    items.push({
      title: `${label} (${count})`,
      isSelected: activeCategory?.label === label,
      onClick: () =>
        dispatch(
          slices.xRayLibrary.actions.setCategory({ label, type: label }),
        ),
    });
  });

  return (
    <Panel
      enableContentPadding={false}
      title={`Categories (${categoryLabels.size})`}
      width="280px"
    >
      <ListItems as="ul" items={items} enablePadding={true} />
    </Panel>
  );
};
const getSortedCategoryLabels = (
  models: LibraryModelVersion[],
): Map<string, number> => {
  const categoryLabels = new Map<string, number>();
  models.forEach((model) => {
    const label = model.tags?.[0]?.label ?? 'Uncategorized';
    if (categoryLabels.has(label)) {
      const count = categoryLabels.get(label) ?? 0;
      categoryLabels.set(label, count + 1);
    } else {
      categoryLabels.set(label, 1);
    }
  });
  const labels = Array.from(categoryLabels.entries());
  const sortedLabels = labels.sort((a, b) => sortStrings(a[0], b[0]));

  return new Map<string, number>(sortedLabels);
};
