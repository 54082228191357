import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  CardSelect,
  Divider,
  Layout,
  Modal,
  Section,
  Text,
  useModal,
  useToast,
} from '~/eds';
import { api, slices } from '~/redux';
import { RoutePathType, useRouting } from '~/routing';

import { ModelPreview } from '../ModelLibrary/ModelPreview';
import { CategoryScope } from './CategoryScope';
import { GlobalScope } from './GlobalScope';
import {
  updateAllModelsCustomScope,
  updateAllModelsGlobalScope,
} from './utils';

type Callback = () => void;

type ScopeType = 'global' | 'custom';

interface Props {
  onBack: Callback;
  onCancel: Callback;
}

export const ModelAddScope = ({ onBack, onCancel }: Props) => {
  const { navigate } = useRouting();
  const { toast } = useToast();
  const [scopeType, setScopeType] = useState<ScopeType>('global');

  const filters = useSelector(slices.xRayLibrary.selectors.selectFilters);

  const modelsInCart = useSelector(slices.xRayLibrary.selectors.selectCart);

  const categoryModels = useSelector(
    slices.xRayLibrary.selectors.selectCategoryModels,
  );

  const modelNumber =
    modelsInCart.length === 1
      ? `${modelsInCart.length} model`
      : `${modelsInCart.length} models`;

  const [
    publishLibraryModels,
    { isLoading: isPublishing },
  ] = api.endpoints.publishLibraryModels.useMutation();

  const scopes = useMemo(() => {
    return scopeType === 'global'
      ? updateAllModelsGlobalScope({
          globalFilters: filters.globalFilters,
          modelsInCart,
        })
      : updateAllModelsCustomScope({
          categoryFilters: filters.categoryFilters,
          categoryModels,
        });
  }, [scopeType, filters]);

  const [confirmPublish, showConfirmPublish, hideConfirmPublish] = useModal({
    title: 'Are you sure you want to publish?',
    children: 'TODO [EKP-29508]: Review & Publish',
    primaryAction: {
      isLoading: isPublishing,
      text: isPublishing ? 'Publishing…' : 'Publish',
      onClick: () => {
        publishLibraryModels({
          models: modelsInCart,
          scopes,
          isDraft: false,
        })
          .unwrap()
          .then((publishedModels) => {
            if (publishedModels?.length) {
              toast({
                action: {
                  text: 'View models',
                  onClick: () => navigate(RoutePathType.AutomationHubFields), // TODO: set sorting to show published models
                },
                message: `${publishedModels.length} models are now publishing…`,
                status: 'success',
              });
              hideConfirmPublish();
            }
            // TODO: handle error states
          });
      },
    },
  });

  const [confirmDraft, showConfirmDraft] = useModal({
    title: 'Save as draft',
    children: 'TODO [EKP-29508]: Review & Publish',
  });

  return (
    <Modal
      isFullPage
      isVisible
      title="2. Add Scope"
      headerCalloutContent={
        <Layout direction="column" align="flex-start">
          <Text>Added Models</Text>
          <Text color="action.link">{modelNumber}</Text>
        </Layout>
      }
      backAction={{ text: 'Back', onClick: onBack }}
      primaryAction={{
        text: 'Review & Publish',
        onClick: showConfirmPublish,
      }}
      secondaryActions={[{ text: 'Save as Drafts', onClick: showConfirmDraft }]}
      cancelText="Close"
      onCancel={onCancel}
    >
      <Section
        title="Set scope for all categories"
        description=" Choose between setting a global scope for all categories or setting
        separate scopes for each category."
      >
        <Layout preset="sections">
          <CardSelect
            direction="row"
            name="scope"
            isMulti={false}
            options={[
              {
                label: 'Set Global Scope',
                description:
                  'Apply filters to all categories to choose which documents the models will run on.',
                value: 'global',
                isActive: scopeType === 'global',
              },
              {
                label: 'Set Custom Scope',
                description: 'Define scope for each category. ',
                value: 'custom',
                isActive: scopeType === 'custom',
              },
            ]}
            onChange={(value) => setScopeType(value as ScopeType)}
            value={scopeType}
          />
          <Divider />
          {scopeType === 'global' && <GlobalScope />}
          {scopeType === 'custom' && <CategoryScope />}
        </Layout>
      </Section>
      <ModelPreview />
      {confirmDraft}
      {confirmPublish}
    </Modal>
  );
};
