import { Nullable } from '~/types';

import {
  FieldModel,
  ModelProgress,
  OptimizeState,
  PromptModelPublishStatus,
} from '../types';

export const testIsTerminateProgress = (
  modelProgress: Nullable<ModelProgress>,
) => {
  // special cases when neither state is provided, publishStatus === null indicates a pure draft state (i.e. terminate).
  if (modelProgress === null) {
    return false;
  } else if (
    modelProgress.publishStatus === null &&
    modelProgress.optimizeState === null
  ) {
    return true;
  }

  return (
    ['completed', 'failed', 'suspended'].includes(
      modelProgress.publishStatus!,
    ) || ['done', 'error'].includes(modelProgress.optimizeState!)
  );
};

export const testIsActivePublishStatus = (
  publishStatus: Nullable<PromptModelPublishStatus>,
) => testIsPublishingStatus(publishStatus) || publishStatus === 'completed';

export const testIsOptimizingState = (state: Nullable<OptimizeState>) =>
  state === 'initializing' || state === 'running';

export const testIsPublishingStatus = (
  publishStatus?: Nullable<PromptModelPublishStatus>,
) => publishStatus === 'in_progress' || publishStatus === 'resumed';

// TODO: deprecate in favor of existing checks
export const testIsNonOptimizedModel = (
  model: FieldModel,
  publishStatusChecks: PromptModelPublishStatus[],
) => {
  const { optimizeState, publishStatus } = model;
  return (
    !optimizeState &&
    publishStatus &&
    publishStatusChecks.includes(publishStatus)
  );
};

export const transformTabKeyToStatus = (tabKey?: string) => {
  if (!tabKey) {
    return null;
  }

  switch (tabKey) {
    case 'published':
      return 'published';
    case 'suspended':
      return 'suspended';
    case 'drafts':
      return 'draft';
    case 'deleted':
      return 'deleted';
    case 'all':
    default:
      return null;
  }
};
