import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getSortedCategoryModels } from '~/features/x-ray/library/ModelLibrary/utils';
import { LibraryModelVersion } from '~/features/x-ray/library/types';

import { SliceType } from '../types';
import { LibraryState as State } from './types';

export const initialState: State = {
  modelPreview: null,
  modelsInCart: [],
  selectedCategory: null,
  categoryModels: new Map(),
  filters: {
    globalFilters: [],
    categoryFilters: {},
  },
};

export default createSlice({
  name: SliceType.XRayLibrary,
  initialState,
  selectors: {
    selectCart: (state) => state.modelsInCart,
    selectCategory: (state) => state.selectedCategory,
    selectModelPreview: (state) => state.modelPreview,
    selectCategoryModels: (state) =>
      getSortedCategoryModels(state.modelsInCart),
    selectFilters: (state) => state.filters,
  },

  reducers: {
    addModelToCart: (state, action: PayloadAction<LibraryModelVersion>) => {
      if (
        state.modelsInCart.find(
          (model) => model.libraryModelId === action.payload.libraryModelId,
        )
      ) {
        return;
      }
      state.modelsInCart = [...state.modelsInCart, action.payload];
    },
    removeModelFromCart: (
      state,
      action: PayloadAction<LibraryModelVersion>,
    ) => {
      const index = state.modelsInCart.findIndex(
        (model) => model.libraryModelId === action.payload.libraryModelId,
      );
      if (index === -1) {
        return;
      }
      state.modelsInCart.splice(index, 1);
    },
    setCategory: (state, action: PayloadAction<State['selectedCategory']>) => {
      state.selectedCategory = action.payload;
    },
    setModelPreview: (state, action: PayloadAction<State['modelPreview']>) => {
      state.modelPreview = action.payload;
    },
    setFilters: (state, action: PayloadAction<State['filters']>) => {
      state.filters = action.payload;
    },
  },
});
